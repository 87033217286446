import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { MoonIcon } from 'components/Icons/V2/MoonIcon';
import { SunIcon } from 'components/Icons/V2/SunIcon';

interface ThemeToggleProps {
  lightLabel: string;
  darkLabel: string;
}

export const SidebarThemeToggle = ({ lightLabel, darkLabel }: ThemeToggleProps) => {
  const [theme, setTheme] = useState(document.documentElement.className || 'light');

  const onClickToggleTheme = () => {
    const className = theme === 'dark' ? 'light' : 'dark';
    setTheme(className);
    document.documentElement.setAttribute('class', className);
  };

  return (
    <button
      type="button"
      className={twMerge(
        clsx(
          'group relative flex h-7 min-w-[99px] max-w-max items-center gap-x-1 rounded-2xl bg-primary-30',
          'px-2 py-0.5 text-general-100 transition-all duration-200 dark:bg-general-100 dark:text-white',
          {
            'flex-row-reverse': theme === 'light'
          }
        )
      )}
      onClick={onClickToggleTheme}
      data-testid="theme-toggle-button"
    >
      <span className="text-center text-xs font-bold" data-testid="theme-toggle-text">
        {theme === 'light' ? lightLabel : darkLabel}
      </span>
      <div className={clsx('flex size-5 items-center justify-center rounded-full bg-white')}>
        {theme === 'light' ? (
          <SunIcon data-testid="theme-toggle-light-icon" />
        ) : (
          <MoonIcon data-testid="theme-toggle-dark-icon" />
        )}
      </div>
    </button>
  );
};
