import { SVGProps } from 'react';

export const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="interface-arrows-button-right--arrow-right-keyboard" clipPath="url(#clip0_1966_487)">
        <path
          id="Vector"
          d="M17.3569 4.94995L9.44979 12.8571C9.39207 12.9186 9.32237 12.9676 9.24497 13.0011C9.16757 13.0346 9.08413 13.0519 8.99979 13.0519C8.91545 13.0519 8.83201 13.0346 8.75461 13.0011C8.67721 12.9676 8.60751 12.9186 8.54979 12.8571L0.642648 4.94995"
          stroke="#1C213C"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
      </g>
      <defs>
        <clipPath id="clip0_1966_487">
          <rect width="18" height="18" fill="white" transform="translate(18) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
};
