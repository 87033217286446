import { useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { t } from 'locale';
import { triggerModal } from 'slices/modal';
import { getIsDrawerMenuOpen } from 'slices/drawerMenu';

import DemoBanner from 'components/Base/EnvironmentBanner';
import { Header, LanguageOption, ProfileMenuItem } from 'components/_V2/Header/Header';
import OnboardingProgressBar from 'components/OnboardingProgressBar/OnboardingProgressBarContainer';
import { languageSelector } from 'selectors/localeSelector';
import { Creators as LocaleCreators, GetLocale } from 'locale/actions';
import { Creators as AccountCreators } from 'actions/account';
import { RootState } from 'store/store';
import { appMenuSelector } from 'selectors/appSelectors';
import { UserProfileIcon } from 'components/Icons/V2/UserProfileIcon';
import { DeleteCircleIcon } from 'components/Icons/V2/DeleteCircleIcon';
import { SignOutIcon } from 'components/Icons/V2/SignOutIcon';
import { AppBarContext } from 'components/AppBar/AppBarContext';

import { localeKeys } from 'enums/localeKeys';

import { useIsLoaded } from 'hooks/useIsLoaded';

import { isEmbeddedView } from 'utils/util';
import { isProduction } from 'utils/checkEnvironment';

const AppBarHeaderComponent = ({
  logoComponent,
  allowedLanguages,
  currentLanguage,
  getLocale,
  fullName,
  signOut,
  deleteUser,
  triggerModal
}: AppBarHeaderPropsFromRedux) => {
  const history = useHistory();

  const {
    isAuthorized,
    isRegisteredFromLink,
    tenantSettings: { magicLinkSettings },
    toggleDrawerMenu,
    isDrawerMenuOpen
  } = useContext(AppBarContext);

  const setDocumentLang = (language: string) => {
    document.documentElement.lang = language;
  };

  useIsLoaded(() => {
    if (currentLanguage) {
      setDocumentLang(currentLanguage);
    }
  });

  const isEmbedded = isEmbeddedView();
  const isEnvironmentProduction = isProduction();

  const allowEditingPersonalData = isRegisteredFromLink
    ? magicLinkSettings.allowEditingPersonalData || magicLinkSettings.allowConventionalLogin
    : true;

  const changeLanguage = (language: string) => {
    getLocale(language);
    setDocumentLang(language);
  };

  const currentLanguageOption = allowedLanguages.find(item => item.value === currentLanguage);
  const allowedLanguagesWithActive = allowedLanguages.map(lang => ({
    ...lang,
    isActive: lang.value === currentLanguage
  })) as LanguageOption[];

  const handleEditAccount = () => history.push(routes.personalDetails.path);
  const handleDeleteAccount = () => {
    triggerModal({
      title: t(localeKeys.deleteUserTitle),
      children: <div>{t(localeKeys.deleteUserMessage)}</div>,
      options: {
        confirmLabel: t(localeKeys.deleteText),
        onConfirm: deleteUser,
        isConfirmDestructive: true,
        showCancel: true
      }
    });
  };
  const handleSignOut = () => {
    triggerModal({
      title: t(localeKeys.signOutTitle),
      children: <div>{t(localeKeys.signOutMessage)}</div>,
      options: {
        confirmLabel: t(localeKeys.signOutContinueButton),
        onConfirm: signOut,
        showCancel: true
      }
    });
  };

  const profileItems = [
    allowEditingPersonalData && {
      label: t(localeKeys.appMenuPersonalDetailsText),
      value: 'personal-details',
      Icon: UserProfileIcon,
      onClick: handleEditAccount
    },
    {
      label: t(localeKeys.appMenuDeleteUser),
      value: 'delete-account',
      Icon: DeleteCircleIcon,
      onClick: handleDeleteAccount
    },
    {
      label: t(localeKeys.appMenuSignOut),
      value: 'sign-out',
      Icon: SignOutIcon,
      onClick: handleSignOut
    }
  ].filter(Boolean) as ProfileMenuItem[];

  const handleChangeLanguage = (item: LanguageOption) => {
    changeLanguage(item.value);
  };

  return !isEmbedded ? (
    <Header
      name="AppBarHeader"
      userName={fullName}
      renderLogo={() => logoComponent}
      isLoggedIn={isAuthorized}
      profileMenuItems={profileItems}
      languages={allowedLanguagesWithActive}
      selectedLanguage={currentLanguageOption as LanguageOption}
      isCollapsed={!isDrawerMenuOpen}
      onChangeCollapse={toggleDrawerMenu}
      onChangeLanguage={handleChangeLanguage}
      onClickLogin={() => {
        history.push(routes.login.path);
      }}
    >
      {!isEnvironmentProduction && <DemoBanner />}
      <OnboardingProgressBar />
    </Header>
  ) : null;
};

const mapStateToProps = (state: RootState) => {
  const { options, lang } = languageSelector(state);
  const { fullName } = appMenuSelector(state);
  const isDrawerMenuOpen = getIsDrawerMenuOpen(state);

  return {
    allowedLanguages: options,
    currentLanguage: lang,
    fullName,
    isDrawerMenuOpen
  };
};

const connector = connect(mapStateToProps, {
  getLocale: LocaleCreators.getLocale as GetLocale,
  triggerModal: triggerModal,
  signOut: AccountCreators.signOut,
  deleteUser: AccountCreators.deleteUser
});

type AppBarHeaderPropsFromRedux = ConnectedProps<typeof connector> & { logoComponent: JSX.Element };

export const AppBarHeader = connector(AppBarHeaderComponent);
