import React from 'react';
import { Route } from 'slices/routes';
import { ToastContainer } from 'react-toastify';

import { AppLayoutBuilder } from 'components/Base/AppLayoutBuilder';
import { Footer } from 'components/_V2/Footer/Footer';
import { Snackbar } from 'components/Snackbar/Snackbar';
import ErrorToastContainer from 'components/_V2/ErrorToast/ErrorToastContainer';
import GlobalModalContainer from 'components/_V2/GlobalModal/GlobalModalContainer';
import { SidebarNavigationContainer } from 'components/_V2/SidebarNavigation/SidebarNavigationContainer';

import { history } from 'routes/history';
import { Navigation } from 'routes/Navigation';
import { ProtectedRoutes } from 'routes/ProtectedRoutes';
import { PublicRoutes } from 'routes/PublicRoutes';

interface AppLayoutProps {
  isAuthenticated: boolean;
  isRegisteredFromLink: boolean;
  route: Route;
}

export const AppLayout = ({ isAuthenticated, isRegisteredFromLink, route }: AppLayoutProps) => {
  const isProtectedRouteLayout = isAuthenticated && route.type === 'protected';

  return (
    <React.Fragment>
      <Snackbar />
      <ToastContainer position="top-center" containerId="global-toast" />
      <ErrorToastContainer />
      <GlobalModalContainer />
      <Navigation isAuthenticated={isAuthenticated} isRegisteredFromLink={isRegisteredFromLink} />
      <div className="mx-auto bg-white dark:bg-general-100">
        <SidebarNavigationContainer
          isAuthenticated={isAuthenticated}
          isRegisteredFromLink={isRegisteredFromLink}
        />
        <AppLayoutBuilder
          isProtectedRouteLayout={isProtectedRouteLayout}
          isAuthenticated={isAuthenticated}
        >
          {isAuthenticated ? (
            <ProtectedRoutes
              path={history.location.pathname}
              isRegisteredFromLink={isRegisteredFromLink}
              isAuthenticated={isAuthenticated}
            />
          ) : (
            <PublicRoutes
              path={history.location.pathname}
              isRegisteredFromLink={isRegisteredFromLink}
            />
          )}
        </AppLayoutBuilder>
      </div>
      {!isProtectedRouteLayout && <Footer variant="switchkit" />}
    </React.Fragment>
  );
};
