import { SVGProps } from 'react';

export const AvatarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <g id="Avatar">
        <circle id="BG" cx="18" cy="18" r="18" fill="#0ED4BD" />
        <mask id="mask0_1966_911" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
          <circle id="BG_2" cx="18" cy="18" r="18" fill="white" />
        </mask>
        <g mask="url(#mask0_1966_911)">
          <g id="icon">
            <circle id="Oval" cx="17.9999" cy="33.8002" r="12.6" fill="#CFF6F2" />
            <circle id="Oval_2" cx="18.0001" cy="14.0001" r="5.4" fill="#CFF6F2" />
          </g>
        </g>
      </g>
    </svg>
  );
};
