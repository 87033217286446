import { SVGProps } from 'react';

export const AlertCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g
        id="interface-alert-information-circle--information-frame-info-more-help-point-circle"
        clipPath="url(#clip0_1857_1764)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M8.99996 17.3571C13.6155 17.3571 17.3571 13.6155 17.3571 8.99996C17.3571 4.38444 13.6155 0.642822 8.99996 0.642822C4.38444 0.642822 0.642822 4.38444 0.642822 8.99996C0.642822 13.6155 4.38444 17.3571 8.99996 17.3571Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
        <g id="Group 34">
          <path
            id="Vector_2"
            d="M7 7C7 6.60444 7.1173 6.21776 7.33706 5.88886C7.55682 5.55996 7.86918 5.30362 8.23463 5.15224C8.60009 5.00087 9.00222 4.96126 9.39018 5.03843C9.77814 5.1156 10.1345 5.30608 10.4142 5.58579C10.6939 5.86549 10.8844 6.22186 10.9616 6.60982C11.0387 6.99778 10.9991 7.39992 10.8478 7.76537C10.6964 8.13082 10.44 8.44318 10.1111 8.66294C9.78224 8.8827 9.39556 9 9 9V10"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_3"
            d="M9.00003 13.2857C9.35508 13.2857 9.64289 12.9979 9.64289 12.6429C9.64289 12.2878 9.35508 12 9.00003 12C8.64499 12 8.35718 12.2878 8.35718 12.6429C8.35718 12.9979 8.64499 13.2857 9.00003 13.2857Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_1764">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
