import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Hint } from 'models/Hint';

export type MandateState = {
  hints: Hint[];
};

const initialState: MandateState = {
  hints: []
};

export const mandateSlice = createSlice({
  name: 'mandate',
  initialState,
  reducers: {
    updateHints(state, action: PayloadAction<Hint>) {
      const index = state.hints.findIndex(item => item.creditor === action.payload.creditor);

      if (index !== -1) {
        state.hints[index] = action.payload;
      } else {
        state.hints.push(action.payload);
      }

      return state;
    }
  },
  selectors: {
    getHints: state => state.hints
  }
});

export const { updateHints } = mandateSlice.actions;
export const { getHints } = mandateSlice.selectors;
