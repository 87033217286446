import styled from 'styled-components';

import { Container } from 'components/Container/ContainerStyled';
import { ProgressBarContainer } from 'components/OnboardingProgressBar/OnboardingProgressBarStyled';

import { themeBreakPoints } from 'enums/keys';

const footerAndHeaderHeight = '185px';

export const BodyElement = styled(Container)`
  && {
    margin: 0 auto;
  }
`;

export const ProtectedRouteLayout = styled(Container)`
  && {
    min-height: calc(100vh - ${footerAndHeaderHeight});
  }
`;

export const PublicRouteLayout = styled(ProtectedRouteLayout)`
  && {
    padding: 0;
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    .MuiButton-fullWidth {
      width: 100% !important;
    }
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
  ${ProgressBarContainer} {
    @media (max-width: ${({ theme: { breakpoints } }) =>
        `${breakpoints.values[themeBreakPoints.sm]}px`}) {
      display: none;
    }
  }
`;

interface PublicRouteLayoutStyledDivProps extends React.HTMLAttributes<HTMLDivElement> {
  hasProgressBar?: boolean;
}

export const PublicRouteLayoutStyledDiv = styled(
  ({ hasProgressBar: _, ...props }: PublicRouteLayoutStyledDivProps) => <div {...props} />
)`
  > div {
    &:first-child:not(#circularLoader) {
      padding: 25px 25px 32px 25px;
      margin: ${({ hasProgressBar = false }) => (hasProgressBar ? '0 auto' : '30px auto 0 auto')};
      background-color: ${({ theme: { palette } }) =>
        palette.background.secondary ?? palette.common.white};
      @media (max-width: ${({ theme: { breakpoints } }) =>
          `${breakpoints.values[themeBreakPoints.sm]}px`}) {
        margin: 30px auto 0 auto;
      }
    }
  }
`;

export const DemoBannerStyled = styled.div`
  background-color: ${({ theme }) => theme.bannerColor};
  color: ${({ theme: { palette } }) => palette.darkGrey};
  text-align: center;
  padding: 0.5rem;
  font-size: ${({ theme: { typography } }) => typography.fontSizes.S};
`;
