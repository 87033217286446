import { combineSlices, configureStore, Tuple } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routeSlice } from 'slices/routes';
import { banksSlice } from 'slices/banks';
import { errorSlice } from 'slices/errors';
import { modalSlice } from 'slices/modal';
import { drawerMenuSlice } from 'slices/drawerMenu';
import { mandateSlice } from 'slices/mandates';

import { sagas } from 'sagas';
import { api } from 'api/reducer';
import { app } from 'reducers/app';
import { paymentPartner } from 'reducers/paymentPartner';
import { transferAmount } from 'reducers/transferAmount';
import { locale } from 'locale/reducer';
import { modal } from 'reducers/modal';
import { idCard } from 'reducers/idCard';
import { snackbar } from 'reducers/snackbar';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: combineSlices(
    banksSlice,
    routeSlice,
    errorSlice,
    modalSlice,
    drawerMenuSlice,
    mandateSlice,
    {
      api,
      app,
      paymentPartner,
      transferAmount,
      locale,
      modal,
      idCard,
      snackbar
    }
  ),
  middleware: () => new Tuple(sagaMiddleware)
});

sagaMiddleware.run(sagas);

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = ReturnType<typeof store.dispatch>;
