import { Selector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { RootState } from 'store/store';
import { TransferringAccount } from 'models/TransferringAccount';
import { Types } from 'actions/account';

export const getTransferringAccounts: Selector<RootState['api'], TransferringAccount[]> = ({
  api
}) => get(api, [Types.GET_TRANSFERRING_ACCOUNTS, 'data'], []);
