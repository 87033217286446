import React, { useMemo, useState } from 'react';

import { Modal } from 'components/_V2/Modal/Modal';

import { useIsUpdated } from 'hooks/useIsUpdated';

import { GlobalModalPropsFromRedux } from './GlobalModalContainer';

export const GlobalModal = ({
  options,
  title,
  children,
  clearModal,
  ...props
}: GlobalModalPropsFromRedux) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const memoizedConfig = useMemo(() => {
    return {
      title,
      children
    };
  }, [title, children]);

  useIsUpdated(() => {
    const { title, children } = memoizedConfig;
    if (title || children) {
      setIsOpen(true);
    }
  }, memoizedConfig);

  return (
    <Modal
      data-testid="global-modal"
      {...props}
      title={title}
      isConfirmDestructive={options?.isConfirmDestructive}
      isSuccess={options?.isSuccess}
      showCancel={options?.showCancel}
      confirmLabel={options?.confirmLabel}
      isOpen={isOpen}
      onConfirm={() => {
        options?.onConfirm?.();
        setIsOpen(false);
        clearModal();
      }}
      onCancel={() => {
        options?.onCancel?.();
        setIsOpen(false);
        clearModal();
      }}
    >
      {children}
    </Modal>
  );
};
