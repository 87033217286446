import dayjs from 'dayjs';

export const formatDate = (value: dayjs.Dayjs | Date | null | undefined, format = 'YYYY-MM-DD') => {
  const date = dayjs(value);

  return date.isValid() ? date.format(format) : null;
};

export const parseDate = (value: string | null | undefined, format = 'YYYY-MM-DD') => {
  const date = dayjs(value, format);

  return date.isValid() ? date.toDate() : null;
};
