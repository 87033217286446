import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { noop } from 'lodash';
import { t } from 'locale';
import clsx from 'clsx';

import { CloseIcon } from 'components/Icons/V2/CloseIcon';
import { SuccessCircleIcon } from 'components/Icons/V2/SuccessCircleIcon';
import { Button } from 'components/_V2/Button/Button';

import { localeKeys } from 'enums/localeKeys';

import { useIsUpdated } from 'hooks/useIsUpdated';

export interface ModalProps {
  isOpen?: boolean;
  title?: string;
  children: React.ReactNode;
  onCancel?: () => void;
  cancelLabel?: string;
  onConfirm?: () => void;
  confirmLabel?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  isConfirmDestructive?: boolean;
  isSuccess?: boolean;
}

enum KeyEventEnum {
  Enter = 'Enter',
  Escape = 'Escape'
}

export const Modal: React.FC<ModalProps> = ({
  isOpen = false,
  title,
  children,
  onCancel = noop,
  cancelLabel = t(localeKeys.closeButton),
  onConfirm = noop,
  confirmLabel = t(localeKeys.confirm),
  showCancel = true,
  showConfirm = true,
  isConfirmDestructive = false,
  isSuccess = false
}) => {
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const previousFocusedElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KeyEventEnum.Escape && onCancel) {
        onCancel();
      }
    };

    if (isOpen && showCancel) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onCancel, showCancel]);

  useIsUpdated(() => {
    if (isOpen) {
      previousFocusedElement.current = document.activeElement as HTMLElement;
      backdropRef.current?.focus();
    } else {
      previousFocusedElement.current?.focus();
    }
  }, isOpen);

  const onClickOverlay = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.target === event.currentTarget && showCancel) {
      onCancel();
    }
  };

  return !isOpen
    ? null
    : createPortal(
        <div
          tabIndex={0}
          role="button"
          aria-label={t(localeKeys.closeButton)}
          data-testid="modal-backdrop"
          className="fixed left-0 top-0 z-[1000] flex size-full items-center justify-center bg-black/30 outline-none"
          onClick={onClickOverlay}
          onKeyDown={event => {
            if (event.key === KeyEventEnum.Enter) {
              onClickOverlay(event);
            }
          }}
          ref={backdropRef}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            className="flex w-[272px] animate-fadeOpacity flex-col rounded-md bg-white p-6 shadow-dropdown dark:bg-general-80 md:w-[550px]"
            data-testid="modal"
            role="dialog"
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
            aria-modal="true"
            onKeyDown={event => {
              if (event.key === KeyEventEnum.Enter) {
                event.stopPropagation();
              }
            }}
          >
            {showCancel && (
              <div className="flex justify-end">
                <button
                  type="button"
                  className="flex size-6 items-center justify-center"
                  data-testid="modal-close-button"
                  onClick={onCancel}
                  aria-label={t(localeKeys.closeButton)}
                >
                  <CloseIcon className="dark:[&_.path]:stroke-white" />
                </button>
              </div>
            )}
            {title && (
              <div className={clsx('flex flex-col', { 'items-center': isSuccess })}>
                {isSuccess && (
                  <>
                    <SuccessCircleIcon data-testid="modal-success-icon" />
                    <div className="h-4" />
                  </>
                )}
                <h4
                  id="modal-title"
                  className="!mb-2 text-center text-general-100 dark:text-white md:text-left"
                  data-testid="modal-title"
                >
                  {title}
                </h4>
              </div>
            )}
            <div
              className="text-body-standard-regular text-general-100 dark:text-general-10"
              id="modal-content"
            >
              {children}
            </div>
            <div className="h-4" />
            <div className={clsx('flex justify-center gap-x-4', { 'md:justify-end': !isSuccess })}>
              {showConfirm && (
                <Button
                  variant={isConfirmDestructive ? 'destructive' : 'primary'}
                  data-testid="modal-confirm-button"
                  size="small"
                  onClick={onConfirm}
                >
                  {confirmLabel}
                </Button>
              )}
              {showCancel && (
                <Button
                  variant="tertiary"
                  className=""
                  onClick={onCancel}
                  size="small"
                  data-testid="modal-cancel-button"
                >
                  {cancelLabel}
                </Button>
              )}
            </div>
          </div>
        </div>,
        document.body
      );
};

export default Modal;
