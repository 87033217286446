import { connect, ConnectedProps } from 'react-redux';
import { clearError } from 'slices/errors';

import { errorSelector } from 'selectors/errorSelector';
import { RootState } from 'store/store';

import { ErrorToast } from './ErrorToast';

const mapStateToProps = (state: RootState) => errorSelector(state);

const connector = connect(mapStateToProps, {
  clearError
});

export type ErrorToastPropsFromRedux = ConnectedProps<typeof connector>;

const ErrorToastContainer = connector(ErrorToast);

export default ErrorToastContainer;
