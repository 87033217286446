import { SVGProps } from 'react';

export const AtmCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g
        id="money-atm-card-3--deposit-money-payment-finance-atm-withdraw"
        clipPath="url(#clip0_1857_966)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M16.0714 8.35718H1.92854C1.21846 8.35718 0.642822 8.93281 0.642822 9.64289V16.0715C0.642822 16.7815 1.21846 17.3572 1.92854 17.3572H16.0714C16.7815 17.3572 17.3571 16.7815 17.3571 16.0715V9.64289C17.3571 8.93281 16.7815 8.35718 16.0714 8.35718Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_2"
            d="M4.5 2.57129V5.14272"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_3"
            d="M9 0.642822V5.14282"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_4"
            d="M13.5 2.57129V5.14272"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_5"
            d="M8.99986 14.7856C10.065 14.7856 10.9284 13.9222 10.9284 12.857C10.9284 11.7919 10.065 10.9285 8.99986 10.9285C7.93474 10.9285 7.07129 11.7919 7.07129 12.857C7.07129 13.9222 7.93474 14.7856 8.99986 14.7856Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_966">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
