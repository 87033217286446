import 'styles/main.css';
import { createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { theme } from 'theme';
import { Router } from 'routes';
import { StyledEngineProvider } from '@mui/material';

import { ErrorBoundary } from 'components/_V2/ErrorBoundary/ErrorBoundary';
import { MainContainer } from 'components/Main/MainContainer';
import { store } from 'store/store';

// @ts-ignore
const themeSettings = createTheme(theme);

const App = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <MainContainer theme={themeSettings}>
          <ErrorBoundary>
            <Router />
          </ErrorBoundary>
        </MainContainer>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
