import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { nanoid } from 'nanoid';

import { createReducer } from 'utils/redux';

import { Types } from './actions';

const INITIAL_STATE = {
  isFetching: false,
  errorType: null,
  error: null
};

const handlers = {
  [Types.API_REQUEST]: (state = INITIAL_STATE, action) => {
    return {
      ...state,
      isFetching: true,
      [action.actionType]: {
        ...state[action.actionType],
        isFetching: true,
        isRequestCompleted: false,
        requestData: action.payload
      }
    };
  },
  [Types.API_RESPONSE]: (state = INITIAL_STATE, action) => {
    return {
      ...state,
      errorType: null,
      error: null,
      isFetching: false,
      [action.actionType]: {
        ...state[action.actionType],
        isFetching: false,
        isRequestCompleted: true,
        data: action.payload
      }
    };
  },
  [Types.API_ERROR]: (state = INITIAL_STATE, action) => {
    const error = {
      errorType: action.errorType,
      error: {
        ...action.error,
        uuid: nanoid()
      },
      isFetching: false
    };

    return {
      ...state,
      ...error,
      [action.actionType]: {
        ...state[action.actionType],
        ...error,
        data: undefined
      }
    };
  },
  [Types.API_RESET]: (state = INITIAL_STATE, action) => {
    const { except } = action;

    return except ? pick(state, [...except, 'errorType', 'error']) : INITIAL_STATE;
  },
  [Types.API_ERROR_RESET]: (state = INITIAL_STATE) => {
    return {
      ...state,
      ...INITIAL_STATE
    };
  },
  [Types.UPDATE_ACTION_RESPONSE]: (state = INITIAL_STATE, action) => {
    return {
      ...state,
      [action.actionType]: {
        ...state[action.actionType],
        isFetching: false,
        data: action.payload
      }
    };
  },
  [Types.API_RESET_BY_KEYS]: (state = INITIAL_STATE, action) => {
    const { keysToReset } = action;
    return omit(state, keysToReset);
  }
};

export const api = createReducer(INITIAL_STATE, handlers);
