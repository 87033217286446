import { Selector, createSelector } from '@reduxjs/toolkit';

import { getAllowedLanguages } from 'selectors/tenantSettingsSelector';
import { RootState } from 'store/store';
import { Language, LocaleState } from 'locale/reducer';

export const getLang: Selector<RootState, string> = ({ locale: { lang } }) => lang;

export const getLocale: Selector<RootState, LocaleState> = ({ locale }) => locale;

export const localeSelector: Selector<RootState, LocaleState> = createSelector(
  [getLocale],
  locale => ({
    ...locale
  })
);

export const languageSelector: Selector<
  RootState,
  { options: { label: string; value: Language }[]; lang: Language }
> = createSelector([getAllowedLanguages, getLang], (options, lang) => {
  return {
    options,
    lang
  };
});
