import { SVGProps } from 'react';

export const WarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g id="Icons">
        <g
          id="interface-alert-warning-triangle--frame-alert-warning-triangle-exclamation-caution"
          clipPath="url(#clip0_3492_52469)"
        >
          <g id="Group">
            <path
              id="Vector"
              d="M12 9.42773V13.2849"
              stroke="#F0142F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M12.0022 17.7857C12.3573 17.7857 12.6451 17.4979 12.6451 17.1429C12.6451 16.7878 12.3573 16.5 12.0022 16.5C11.6472 16.5 11.3594 16.7878 11.3594 17.1429C11.3594 17.4979 11.6472 17.7857 12.0022 17.7857Z"
              stroke="#F0142F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M13.146 4.34987C13.0381 4.13927 12.8742 3.96253 12.6723 3.83911C12.4704 3.71569 12.2383 3.65039 12.0017 3.65039C11.7651 3.65039 11.533 3.71569 11.3311 3.83911C11.1292 3.96253 10.9653 4.13927 10.8574 4.34987L3.78598 18.4927C3.68735 18.6885 3.64039 18.9061 3.64956 19.1251C3.65874 19.3441 3.72373 19.5571 3.83839 19.7439C3.95304 19.9307 4.11353 20.0851 4.30463 20.1924C4.49573 20.2997 4.71109 20.3564 4.93026 20.357H19.0731C19.2923 20.3564 19.5076 20.2997 19.6987 20.1924C19.8898 20.0851 20.0503 19.9307 20.165 19.7439C20.2796 19.5571 20.3446 19.3441 20.3538 19.1251C20.363 18.9061 20.316 18.6885 20.2174 18.4927L13.146 4.34987Z"
              stroke="#F0142F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3492_52469">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};
