import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import React from 'react';
import { t } from 'locale';
import { IGNORED_ERRORS } from 'apm/sentryAgent';
import { FallbackRender } from '@sentry/react/types/errorboundary';

import { WarningIcon } from 'components/Icons/V2/WarningIcon';
import { Button } from 'components/_V2/Button/Button';

import { localeKeys } from 'enums/localeKeys';

const ErrorBoundaryFallback: FallbackRender = errorData => {
  if (IGNORED_ERRORS.some(regex => regex.test(errorData.error.message))) {
    return <div />;
  }

  let errorMessage = t(localeKeys.errorBoundaryMessage);
  let reloadButtonText = t(localeKeys.errorReloadButton);

  if (errorMessage === localeKeys.errorBoundaryMessage) {
    errorMessage = 'Something went wrong.';
  }

  if (reloadButtonText === localeKeys.errorReloadButton) {
    reloadButtonText = 'Reload';
  }

  return (
    <div className="flex flex-col items-center gap-y-2 pt-20">
      <WarningIcon height={70} width={70} />
      <h4>{errorMessage}</h4>
      <Button onClick={() => window.location.reload()}>{reloadButtonText}</Button>
    </div>
  );
};

export const ErrorBoundary = ({
  children,
  fallback = ErrorBoundaryFallback,
  ...rest
}: ErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary fallback={fallback} {...rest}>
      {children}
    </SentryErrorBoundary>
  );
};
