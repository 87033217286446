import isInteger from 'lodash/isInteger';

export const calculateProgress = (divisor: number, divider: number) => {
  return !divisor || !divider ? 0 : divisor / divider;
};

export const calculateProgressPercentage = ({
  divisor,
  divider,
  decimals = 1
}: {
  divisor: number;
  divider: number;
  decimals?: number;
}) => {
  const progress = calculateProgress(divisor, divider) * 100;
  return isInteger(progress) ? progress : parseFloat(progress.toFixed(decimals));
};
