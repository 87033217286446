import ReactCountryFlag from 'react-country-flag';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import { t } from 'locale';

import { ArrowDownIcon } from 'components/Icons/V2/ArrowDownIcon';
import { CatalanFlagIcon } from 'components/Icons/V2/CatalanFlagIcon';

import { countryCodes } from 'enums/keys';

import { countryCodeConvert, languageTranslationsMap } from 'utils/languageUtils';

import { HeaderProps, LanguageOption } from './Header';

import { DropdownSelector } from '../DropdownSelector/DropdownSelector';

type HeaderLanguageDropdownProps = Pick<
  HeaderProps,
  'languages' | 'selectedLanguage' | 'onChangeLanguage'
>;

interface MenuItemLanguageOption extends LanguageOption {
  isActive?: boolean;
}

export const HeaderLanguageSelection = ({
  languages,
  selectedLanguage,
  onChangeLanguage
}: HeaderLanguageDropdownProps) => {
  const title = t(languageTranslationsMap[selectedLanguage.label]?.title ?? selectedLanguage.label);
  return (
    <DropdownSelector<MenuItemLanguageOption>
      name="language-selector"
      onClickItem={item => {
        onChangeLanguage(item);
      }}
      renderTitle={({ isDropdownOpen }) => (
        <Fragment>
          <div className="flex items-center gap-2">
            {selectedLanguage.value === countryCodes.ca ? (
              <CatalanFlagIcon width={24} height={18} />
            ) : (
              <ReactCountryFlag
                svg
                alt={selectedLanguage.value}
                countryCode={countryCodeConvert(selectedLanguage.value)}
                className="mr-2"
                style={{
                  width: '24px',
                  height: '18px'
                }}
              />
            )}

            <span
              className={clsx(
                `text-body-standard-regular text-general-100 dark:text-white`,
                { 'font-bold': isDropdownOpen },
                'before:text-body-standard-regular before:block before:h-0 before:overflow-hidden before:font-bold before:content-[attr(data-title)]'
              )}
              data-testid="language-selector-title"
              data-title={title}
            >
              {title}
            </span>
          </div>

          <ArrowDownIcon
            className={clsx(
              'transition-all duration-300 [&_.path]:stroke-primary-100 dark:[&_.path]:stroke-white',
              {
                'rotate-180': isDropdownOpen
              }
            )}
            data-testid="language-selector-toggle"
          />
        </Fragment>
      )}
      renderItem={({ item, index, onClickItem, setIsDropdownOpen }) => {
        const countryCode = countryCodeConvert(item.value as string);

        return (
          <button
            type="button"
            onClick={() => {
              onClickItem(item);
              setIsDropdownOpen(false);
            }}
            className={clsx('flex w-full items-center justify-start gap-x-2 p-4')}
            data-testid={`language-selector-item-button-${index}`}
          >
            <div className="flex">
              {item.value === countryCodes.ca ? (
                <CatalanFlagIcon
                  data-testid={`language-selector-item-flag-${index}`}
                  width={24}
                  height={18}
                />
              ) : (
                <ReactCountryFlag
                  svg
                  alt={countryCode}
                  countryCode={countryCode}
                  style={{
                    width: '24px',
                    height: '18px'
                  }}
                  data-testid={`language-selector-item-flag-${index}`}
                />
              )}
            </div>
            <div className="flex flex-col">
              <span
                className={clsx(
                  'text-body-standard-regular flex break-all text-general-80 peer-checked:text-general-100 dark:text-white dark:peer-checked:font-medium dark:peer-checked:text-white',
                  {
                    'font-bold': item.isActive
                  }
                )}
                data-testid={`language-menu-item-title-${index}`}
              >
                {t(languageTranslationsMap[item.label as string]?.title ?? item.label)}
              </span>
              <span
                className="text-body-smaller-regular flex break-all text-general-80 peer-checked:text-general-100 dark:text-white dark:peer-checked:font-medium dark:peer-checked:text-white"
                data-testid={`language-menu-item-subtitle-${index}`}
              >
                {t(languageTranslationsMap[item.label as string]?.subtitle ?? '')}
              </span>
            </div>
          </button>
        );
      }}
      items={languages}
    />
  );
};
