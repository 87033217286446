import { createActions } from 'utils/redux';

type SignupCarousel = 'modal' | 'static' | 'none';

export interface MagicLinkSettings {
  allowConventionalLogin: boolean;
  allowEditingPersonalData: boolean;
  showPersonalDataFormAfterSignup: boolean;
  requireLinkUse: boolean;
  requireLinkMessage: string;
}

export type TenantOperationalCountry = 'DE' | 'AT' | 'ES';

export interface TenantSettings {
  allowNoOnlineBankingAccess: boolean;
  collectPaymentPartnerEmail: boolean;
  creditorLogoSource: string;
  defaultLanguage: string;
  disableUserRegistration: boolean;
  disableStandingOrders: boolean;
  disableBalanceTransfer: boolean;
  disableCloseAccount: boolean;
  disableOverdraft: boolean;
  disableNewIbanValidation: boolean;
  disableTan: boolean;
  enableMultiAccountSwitch: boolean;
  enableSepaDdForBalance: boolean;
  enableSme: boolean;
  enablePersonal: boolean;
  faviconUrl: string | null;
  logo: string | null;
  languageSwitcher: boolean;
  manualTransferPrompt: boolean;
  standingOrderManual: boolean;
  tenantId: string;
  uploadIdentification: boolean;
  idCardIntegrationStyle: string;
  bankName: string;
  enableDataAnalytics: boolean;
  operationalCountry: TenantOperationalCountry;
  magicLinkSettings: MagicLinkSettings;
  active: boolean;
  validSortCodes: string[];
  associatedSortCodes: string[];
  sameBankError: string | null;
  typeTermsConditions: 'short' | 'long';
  allowedLanguages: { code: string; language: string; locale: string }[];
  tenantInformal: boolean;
  mobileAsk: {
    showHeader: boolean;
    tenantVisuals: Record<string, unknown>;
    signupCarouselMobile: SignupCarousel;
    signupCarouselDesktop: SignupCarousel;
    headTitle: string;
    socialMediaMetaTags: string;
    metaDescription: string;
    navigationBarHtml: string;
    loginCoverHtml: string;
    footerHtml: string;
    customCss: string;
  };
  loginImageUrl: string;
  loginImageRefUrl: string;
}

export type HideAppMenuActionCreator = (disable: boolean) => void;

export const { Types, Creators } = createActions({
  setSession: ['payload'],
  getTenantSettings: ['payload'],
  getFeatureToggles: [],
  getTenantIcons: [],
  requestStatus: ['isFetching'],
  initializeApp: [],
  clearStore: [],
  hideAppMenu: ['disable'],
  sessionStatus: [],
  showLoader: ['isLoading']
});
