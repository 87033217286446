import { takeEvery } from 'redux-saga/effects';

import { Types } from 'actions/idCard';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

const { apis } = config;

const { GET_ID_CARD, SAVE_ID_CARD } = Types;

export function* getIdCard() {
  const params = {
    path: apis.accountIdCard
  };

  return yield fetchSaga({
    params,
    actionType: GET_ID_CARD,
    fields: 'idCard'
  });
}

export function* saveIDCard({ payload }) {
  const params = {
    method: httpMethods.POST,
    path: apis.accountIdCard,
    data: {
      idCard: {
        ...payload
      }
    }
  };

  yield fetchSaga({
    params,
    payload,
    actionType: SAVE_ID_CARD,
    fields: 'idCard'
  });

  return yield getIdCard();
}

export function* idCardSaga() {
  yield takeEvery(GET_ID_CARD, getIdCard);
  yield takeEvery(SAVE_ID_CARD, saveIDCard);
}
