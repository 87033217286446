import { SVGProps } from 'react';

export const MoonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g
        id="interface-weather-cresent-moon-1--night-new-moon-crescent-weather-time-waning"
        clipPath="url(#clip0_1872_1525)"
      >
        <path
          id="Vector"
          d="M6.85735 6.00002C6.86139 4.96084 7.13525 3.94053 7.65212 3.039C8.169 2.13748 8.91116 1.38565 9.80592 0.85716C9.14242 0.582459 8.43256 0.436997 7.71449 0.428589C6.23686 0.428589 4.81975 1.01558 3.7749 2.06042C2.73005 3.10527 2.14307 4.52238 2.14307 6.00002C2.14307 7.47765 2.73005 8.89477 3.7749 9.93961C4.81975 10.9845 6.23686 11.5714 7.71449 11.5714C8.44123 11.5663 9.16013 11.4208 9.83164 11.1429C8.93209 10.6176 8.1848 9.86715 7.66329 8.96541C7.14178 8.06367 6.86402 7.04168 6.85735 6.00002V6.00002Z"
          stroke="#1C213C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1872_1525">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
