import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const overviewRoutes: Record<string, Route> = {
  overview: {
    id: 'overview',
    title: 'navigationOverview',
    path: '/overview',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('pages/Overview/OverviewContainer')
    })
  }
};

export default overviewRoutes;
