import { SVGProps } from 'react';

export const DeleteCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g
        id="interface-delete-circle--button-delete-remove-add-circle-buttons"
        clipPath="url(#clip0_1857_734)"
      >
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M11.7256 6.27417L6.27417 11.7256"
              stroke="#1C213C"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="path"
            />
            <path
              id="Vector_2"
              d="M6.27417 6.27417L11.7256 11.7256"
              stroke="#1C213C"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="path"
            />
          </g>
          <path
            id="Vector_3"
            d="M8.99996 17.3571C13.6155 17.3571 17.3571 13.6155 17.3571 8.99996C17.3571 4.38444 13.6155 0.642822 8.99996 0.642822C4.38444 0.642822 0.642822 4.38444 0.642822 8.99996C0.642822 13.6155 4.38444 17.3571 8.99996 17.3571Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_734">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
