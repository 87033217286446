import ReactCountryFlag from 'react-country-flag';
import React from 'react';
import { t } from 'locale';

import { Language } from 'locale/reducer';
import { CatalanFlagIcon } from 'components/Icons/V2/CatalanFlagIcon';
import { RadioGroup } from 'components/_V2/RadioGroup/RadioGroup';
import { SidebarNavigationProps } from 'components/_V2/SidebarNavigation/SidebarNavigation';

import { countryCodes } from 'enums/keys';

import { countryCodeConvert, languageTranslationsMap } from 'utils/languageUtils';

type SidebarLanguageSelectionProps = Pick<
  SidebarNavigationProps,
  'languages' | 'selectedLanguage' | 'onChangeLanguage'
>;

export const SidebarLanguageSelection = ({
  languages,
  selectedLanguage,
  onChangeLanguage
}: SidebarLanguageSelectionProps) => {
  return (
    <RadioGroup
      name="language"
      id="language"
      options={languages}
      value={selectedLanguage}
      onChange={({ target: { value } }) => onChangeLanguage(value as Language)}
      renderOptionLabel={({ value: language, label }, index) => {
        const countryCode = countryCodeConvert(language as string);
        const isSelected = language === selectedLanguage;

        return (
          <div
            className="flex w-full items-center justify-start gap-x-3"
            data-testid={`language-menu-item-${index}`}
            data-selected={isSelected}
          >
            <div className="flex basis-6/12 flex-col">
              <span
                className="flex break-all text-base capitalize text-general-80 peer-checked:text-general-100 dark:text-white dark:peer-checked:font-medium dark:peer-checked:text-white"
                data-testid={`language-menu-item-title-${index}`}
              >
                {t(languageTranslationsMap[label]?.title ?? label)}
              </span>
              <span
                className="flex break-all text-xs text-general-80 peer-checked:text-general-100 dark:text-white dark:peer-checked:font-medium dark:peer-checked:text-white"
                data-testid={`language-menu-item-subtitle-${index}`}
              >
                {t(languageTranslationsMap[label]?.subtitle ?? '')}
              </span>
            </div>
            <div className="flex basis-2/12">
              {language === countryCodes.ca ? (
                <CatalanFlagIcon
                  data-testid={`language-menu-item-flag-${index}`}
                  width={24}
                  height={18}
                />
              ) : (
                <ReactCountryFlag
                  svg
                  alt={countryCode}
                  countryCode={countryCode}
                  style={{
                    width: '24px',
                    height: '18px'
                  }}
                  data-testid={`language-menu-item-flag-${index}`}
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
