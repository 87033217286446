import { connect, ConnectedProps } from 'react-redux';
import { clearModal, getModalsSelector } from 'slices/modal';

import { RootState } from 'store/store';

import { GlobalModal } from './GlobalModal';

const mapStateToProps = (state: RootState) => getModalsSelector(state);

const connector = connect(mapStateToProps, {
  clearModal
});

export type GlobalModalPropsFromRedux = ConnectedProps<typeof connector>;

const GlobalModalContainer = connector(GlobalModal);

export default GlobalModalContainer;
