import { SVGProps } from 'react';

export const UserProfileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="interface-user-multiple--close-geometric-human-multiple-person-up-user">
        <g id="Group">
          <path
            id="Vector"
            d="M6.4285 7.71443C8.02618 7.71443 9.32136 6.41925 9.32136 4.82157C9.32136 3.22389 8.02618 1.92871 6.4285 1.92871C4.83082 1.92871 3.53564 3.22389 3.53564 4.82157C3.53564 6.41925 4.83082 7.71443 6.4285 7.71443Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_2"
            d="M12.2143 17.3571H0.642822V16.0714C0.642822 14.5369 1.25239 13.0653 2.33742 11.9802C3.42245 10.8952 4.89407 10.2856 6.42854 10.2856C7.963 10.2856 9.43462 10.8952 10.5197 11.9802C11.6047 13.0653 12.2143 14.5369 12.2143 16.0714V17.3571Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_3"
            d="M11.5713 1.92871C12.3385 1.92871 13.0743 2.23349 13.6168 2.77601C14.1594 3.31853 14.4641 4.05433 14.4641 4.82157C14.4641 5.5888 14.1594 6.32461 13.6168 6.86713C13.0743 7.40964 12.3385 7.71443 11.5713 7.71443"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_4"
            d="M13.6284 10.5303C14.7236 10.9469 15.6665 11.6863 16.3323 12.6505C16.9981 13.6148 17.3554 14.7585 17.357 15.9303V17.3574H15.4284"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
    </svg>
  );
};
