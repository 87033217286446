import React, { Fragment, ReactNode } from 'react';
import clsx from 'clsx';

import { ArrowDownIcon } from 'components/Icons/V2/ArrowDownIcon';

import { HeaderProps, ProfileMenuItem } from './Header';

import { DropdownSelector } from '../DropdownSelector/DropdownSelector';

type HeaderProfileMenuProps = Pick<HeaderProps, 'profileMenuItems' | 'userName'>;

export const HeaderProfileMenu = ({ profileMenuItems, userName }: HeaderProfileMenuProps) => {
  return (
    <DropdownSelector<ProfileMenuItem>
      name="profile-menu"
      onClickItem={item => {
        item.onClick(item);
      }}
      renderTitle={({ isDropdownOpen }: { isDropdownOpen: boolean }): ReactNode => (
        <Fragment>
          <span
            className={clsx(
              `text-body-standard-regular w-[150px] max-w-[150px] truncate text-general-100 dark:text-white`,
              { 'font-bold': isDropdownOpen },
              'before:invisible before:block before:h-0 before:overflow-hidden before:font-bold before:text-transparent before:content-[attr(data-title)]'
            )}
            data-testid="profile-menu-title"
          >
            {userName}
          </span>

          <ArrowDownIcon
            className={clsx(
              'transition-all duration-300 [&_.path]:stroke-primary-100 dark:[&_.path]:stroke-white',
              {
                'rotate-180': isDropdownOpen
              }
            )}
            data-testid="profile-menu-toggle"
          />
        </Fragment>
      )}
      renderItem={({ item, index, onClickItem, setIsDropdownOpen }) => {
        const { label: menuItemLabel, Icon: MenuItemIcon } = item as ProfileMenuItem;
        return (
          <button
            type="button"
            onClick={() => {
              onClickItem(item);
              setIsDropdownOpen(false);
            }}
            className={clsx('text-body-standard-regular flex w-full items-center p-4', {
              'font-bold': item.isActive
            })}
            data-testid={`header-profile-menu-item-button-${index}`}
          >
            {MenuItemIcon && (
              <MenuItemIcon className="mr-2 dark:[&_.path]:stroke-white" width={24} height={24} />
            )}
            <span className="flex basis-10/12 text-left"> {menuItemLabel} </span>
          </button>
        );
      }}
      items={profileMenuItems}
    />
  );
};
