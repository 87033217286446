import { t } from 'locale';

import { localeKeys } from 'enums/localeKeys';

import { Button } from '../Button/Button';

interface FooterProps {
  variant: 'widget' | 'switchkit';
  userId?: string;
  onClickDataPrivacy?: () => void;
  onClickImprint?: () => void;
}

export const Footer = ({ userId, variant, onClickImprint, onClickDataPrivacy }: FooterProps) => {
  return (
    <footer
      className="hidden flex-col items-start justify-between gap-[4px] bg-white p-6 text-sm shadow-header dark:bg-general-90 sm:flex md:flex-row"
      data-testid="footer"
    >
      <section data-testid="footer-left-section" className="flex flex-col gap-[4px]">
        {variant === 'widget' && (
          <>
            <div
              data-testid="footer-left-section-widget"
              className="flex flex-row items-center gap-[6px]"
            >
              <p
                className="text-body-standard-bold text-general-70 dark:text-white"
                data-testid="footer-partnership-text"
              >
                {t(localeKeys.footerPartnershipWith)}
              </p>

              <img
                src="/company_logo.png"
                alt="logo"
                className="h-5 w-[71px]"
                data-testid="company-logo"
              />
            </div>

            <p
              className="text-body-smaller-regular text-general-70 dark:text-white"
              data-testid="footer-partnership-text"
            >
              {t(localeKeys.footerUserId)}: {userId}
            </p>
          </>
        )}
        {variant === 'switchkit' && (
          <div
            data-testid="footer-left-section-widget"
            className="text-body-smaller-regular flex flex-col items-start gap-[6px]"
          >
            <p
              className="text-general-70 dark:text-white"
              data-testid="sidebar-footer-powered-by-text"
            >
              Powered by Qwist GmbH
            </p>
            <img
              src="/company_logo.png"
              alt="logo"
              className="h-5 w-[71px]"
              data-testid="company-logo"
            />
            <p
              className="text-general-70 dark:text-white"
              data-testid="sidebar-footer-powered-by-text"
            >
              ASK V2.0
            </p>
          </div>
        )}
      </section>
      <section
        data-testid="footer-right-section"
        className="flex flex-col md:flex-row md:gap-[6px]"
      >
        {variant === 'widget' && (
          <>
            <Button
              variant="tertiary"
              data-testid="footer-right-imprint-button"
              onClick={onClickImprint}
              className="h-[28px] p-0 md:p-3"
            >
              {t(localeKeys.footerImprint)}
            </Button>
            <Button
              variant="tertiary"
              data-testid="footer-right-data-privacy-button"
              onClick={onClickDataPrivacy}
              className="h-[28px] p-0 md:p-3"
            >
              {t(localeKeys.footerDataPrivacy)}
            </Button>
          </>
        )}
        {variant === 'switchkit' && (
          <p
            className="break-words text-xs text-general-70 dark:text-white"
            data-testid="sidebar-footer-text"
          >
            {t(localeKeys.footerEncryption)} <br />
            {t(localeKeys.footerSecureServer)} <br />
            {t(localeKeys.footerAudits)}
          </p>
        )}
      </section>
    </footer>
  );
};
