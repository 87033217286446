import clsx from 'clsx';
import { ReactNode, Fragment, useState, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ArrowDownIcon } from 'components/Icons/V2/ArrowDownIcon';

import { useIsLoaded } from 'hooks/useIsLoaded';
import { useIsUnloaded } from 'hooks/useIsUnloaded';

export interface DropdownSelectorItem {
  label: ReactNode;
  value: string | number;
  isActive?: boolean;
}

type DropdownSelectorOnClickItem<T> = (item: T) => void;

export type RenderDropdownSelectorItem<T> = ({
  item,
  name,
  index,
  setIsDropdownOpen,
  onClickItem
}: {
  item: T;
  name: DropdownSelectorBasicProps<T>['name'];
  index: number;
  setIsDropdownOpen: (isOpen: boolean) => void;
  onClickItem: DropdownSelectorOnClickItem<T>;
}) => ReactNode;

interface DropdownSelectorBasicProps<T> {
  name: string;
  onClickItem: DropdownSelectorOnClickItem<T>;
  items: T[];
  renderItem?: RenderDropdownSelectorItem<T>;
}

interface DropdownSelectorWithTitle<T> extends DropdownSelectorBasicProps<T> {
  title: ReactNode;
  renderTitle?: never;
}

type DropdownSelectorRenderTitle = ({ isDropdownOpen }: { isDropdownOpen: boolean }) => ReactNode;
interface DropdownSelectorWithRenderTitle<T> extends DropdownSelectorBasicProps<T> {
  title?: never;
  renderTitle: DropdownSelectorRenderTitle;
}

export type DropdownSelectorProps<T> =
  | DropdownSelectorWithTitle<T>
  | DropdownSelectorWithRenderTitle<T>;

export const DropdownSelector = <T extends DropdownSelectorItem>({
  title = '',
  renderTitle,
  name,
  items,
  renderItem,
  onClickItem
}: DropdownSelectorProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const _onClickItem = (item: T) => {
    setIsDropdownOpen(false);
    onClickItem(item);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!dropdownRef.current?.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useIsLoaded(() => {
    document.addEventListener('click', handleClickOutside);
  });

  useIsUnloaded(() => {
    document.removeEventListener('click', handleClickOutside);
  });

  return (
    <div
      ref={dropdownRef}
      className={twMerge(
        clsx(
          'relative flex w-fit items-center justify-between rounded border border-white dark:border-general-90 dark:bg-general-90',
          {
            'border-primary-30 dark:border-primary-70': isDropdownOpen
          }
        )
      )}
    >
      <button
        type="button"
        className="text-body-standard-regular flex items-center gap-x-2 px-4 py-2"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        data-testid={`${name}-button`}
      >
        {renderTitle ? (
          renderTitle({ isDropdownOpen })
        ) : (
          <Fragment>
            <span
              className={clsx(
                `text-general-100 dark:text-white`,
                { 'font-bold': isDropdownOpen },
                'before:invisible before:block before:h-0 before:overflow-hidden before:font-bold before:text-transparent before:content-[attr(data-title)]'
              )}
              data-title={title}
              data-testid={`${name}-button-title`}
            >
              {title}
            </span>

            <ArrowDownIcon
              className={clsx(
                'transition-all duration-300 [&_.path]:stroke-primary-100 dark:[&_.path]:stroke-white',
                {
                  'rotate-180': isDropdownOpen
                }
              )}
              data-testid={`${name}-chevron-icon`}
            />
          </Fragment>
        )}
      </button>
      {isDropdownOpen && (
        <ul
          data-testid={`${name}-dropdown-list`}
          className="absolute left-0 top-[calc(100%+8px)] z-20 w-full animate-fadeOpacity overflow-auto rounded shadow-dropdown dark:shadow-none"
        >
          {items.map((item, index) => {
            return (
              <li
                key={`${name}-item-${index}`}
                data-testid={`${name}-item-${index}`}
                className={twMerge(
                  clsx(
                    'group flex cursor-pointer text-general-100 transition-[background-color] duration-200',
                    'bg-white hover:bg-primary-hover-light hover:font-bold dark:bg-general-90 dark:text-white dark:hover:bg-white-hover-grey',
                    {
                      'bg-primary-hover-light dark:text-white dark:bg-white-hover-grey font-bold':
                        item.isActive
                    }
                  )
                )}
              >
                {renderItem ? (
                  renderItem({ item, name, index, setIsDropdownOpen, onClickItem })
                ) : (
                  <button
                    type="button"
                    onClick={() => _onClickItem(item)}
                    className="flex w-full p-4"
                    data-testid={`${name}-item-button-${index}`}
                  >
                    {item.label}
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
