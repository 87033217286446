import { FormikHelpers as FormikActions } from 'formik';

import { AccountType } from 'models/Account';
import { SignUpLoginDetailsFormValues } from 'components/SignUpLoginDetails/SignUpLoginDetailsForm';
import {
  BusinessAccountDetailsFormState,
  MagicLinkMissingPersonalAccountDetailsFormState,
  PersonalAccountDetailsFormState
} from 'components/PersonalDetails/personalDetailsTypes';
import { IbanFormValues } from 'components/_V2/IBAN/IbanForm';
import { ResetPasswordTokenFormValues } from 'components/_V2/ResetPassword/ResetPasswordTokenForm';
import { ResetPasswordFormValues } from 'components/_V2/ResetPassword/ResetPasswordForm';
import { ResetPasswordFormValuesWithToken } from 'components/_V2/ResetPassword/ResetPassword';
import { Bank } from 'models/Bank';

import { ApiGateway } from 'enums/apiGateway';

import { createActions } from 'utils/redux';

export interface BankLoginFormDataCredential {
  name: string;
  title: string;
  masked: boolean;
  options?: Record<string, unknown>;
  optional?: boolean;
}
export interface BankLoginFormData {
  bankCode: string;
  supported: boolean;
  gateway: ApiGateway;
  advice: string;
  sameBank: boolean;
  id: string;
  errorMessage: null | string;
  loginHint: {
    header: null | string;
    body: null | string;
  };
  gatewaySpecificInfo: Record<string, unknown>;
  credentials: BankLoginFormDataCredential[];
}

type MfaType = 'TEXT' | 'HTML' | 'CHIPTAN' | 'PHOTOTAN' | 'DECOUPLED';

export interface Mfa {
  type: MfaType;
  data: string;
  label: string;
}

export interface RegistrationStatus {
  status: 'ok' | 'mfa' | 'invalid' | 'failed' | 'pending' | 'consent';
  mfa?: Mfa;
  consent?: Mfa;
}

export interface RegShieldRegistration {
  state: string;
  code: string;
  regShieldUserId: string;
  dataAnalyticsTerms: boolean;
  accountType: AccountType;
}

export type CompleteRegShieldRegistrationActionCreator = (payload: RegShieldRegistration) => void;

export type RegisterMfaActionCreator = (
  payload: { mfa: string },
  action: { setSubmitting: (isSubmitting: boolean) => void }
) => void;

export type LoginToOldBankActionCreatorPayload = {
  [key: string]: unknown;
  dataAnalyticsOptIn: boolean;
  acceptTerms: boolean;
};

export type LoginToOldBankActionCreator = (
  payload: LoginToOldBankActionCreatorPayload,
  params: BankLoginFormData,
  accountType: AccountType
) => void;

interface AccountStatusResponse {
  error: boolean;
  errorMessage: string;
  result: boolean;
  options: Record<string, string>[];
}

export enum AccountStatus {
  COMPLETE = 'COMPLETE',
  OPTIONS = 'OPTIONS',
  ANALYZING = 'ANALYZING',
  ERROR = 'ERROR'
}

export interface UpdateEmailValues {
  email: string;
}

export type UpdateEmailActionCreator = (
  payload: UpdateEmailValues,
  action: FormikActions<UpdateEmailValues>
) => void;

export interface UpdatePasswordValues {
  currentPassword: string;
  password: string;
}

export type UpdatePasswordActionCreator = (
  payload: UpdatePasswordValues,
  action: FormikActions<UpdatePasswordValues>
) => void;

export type UpdatePersonalDetailsActionCreator = CreatePersonalDetailsActionCreator;

export type CreatePersonalDetailsActionCreator = (
  payload:
    | PersonalAccountDetailsFormState
    | BusinessAccountDetailsFormState
    | MagicLinkMissingPersonalAccountDetailsFormState,
  action: FormikActions<
    | PersonalAccountDetailsFormState
    | BusinessAccountDetailsFormState
    | MagicLinkMissingPersonalAccountDetailsFormState
  >
) => void;

export interface RedirectChallengeData {
  widgetBaseUrl: string;
  state: string;
  redirectUrl: string;
  regShieldUserId: string;
}

export interface SignUpOldBankRedirectChallengeData extends RedirectChallengeData {
  selectedBank: Bank;
  accountType: AccountType;
  dataAnalyticsTerms: boolean;
}

export interface TransferBalanceRedirectChallengeData extends RedirectChallengeData {
  selectedPaymentAmount: string;
}

export type StartRedirectChallenge = (
  redirectChallengeData: SignUpOldBankRedirectChallengeData | TransferBalanceRedirectChallengeData
) => void;

export const getAccountStatus = (status: AccountStatusResponse) => {
  const { error, result, options } = status || {};
  if (error === true) {
    return AccountStatus.ERROR;
  }

  if (result) {
    return AccountStatus.COMPLETE;
  }

  if (options && options.length) {
    return AccountStatus.OPTIONS;
  }

  if (options === null && !result && !error) {
    return AccountStatus.ANALYZING;
  }
  // TODO: Consider handling this in different way
  return null;
};

export type AutoLoginActionCreator = (payload: { [p: string]: string }) => void;

export type RegisterLoginDetailsActionCreator = (
  payload: Omit<SignUpLoginDetailsFormValues, 'confirmPassword'>,
  action: FormikActions<SignUpLoginDetailsFormValues>
) => void;

export type RegisterIbanActionCreator = (
  payload: IbanFormValues,
  action: FormikActions<IbanFormValues>
) => void;

export type RequestResetPasswordTokenActionCreator = (
  payload: ResetPasswordTokenFormValues,
  action: FormikActions<ResetPasswordTokenFormValues>
) => void;

export type RequestResetPasswordActionCreator = (
  payload: ResetPasswordFormValuesWithToken,
  action: FormikActions<ResetPasswordFormValues>
) => void;

export const { Types, Creators } = createActions({
  userLogin: ['payload', 'action'],
  deleteUser: [],
  autoLogin: ['payload'],
  requestResetPassword: ['payload', 'action'],
  requestResetPasswordToken: ['payload', 'action'],
  addUserDetails: ['payload'],
  registerMfa: ['payload', 'action'],
  registerIban: ['payload', 'action'],
  getIban: ['payload'],
  getAuthStatus: [],
  setAuthStatus: ['payload'],
  createLoginDetails: ['payload'],
  createPersonalDetails: ['payload', 'action'],
  updatePersonalDetails: ['payload', 'action'],
  getPersonalDetails: ['payload'],
  pollAccountStatus: ['payload'],
  registerLoginDetails: ['payload', 'action'],
  accountStatus: ['payload'],
  selectAccount: ['payload', 'action'],
  loginToOldBank: ['payload', 'params', 'accountType'],
  manualAccountSwitchLogin: ['payload', 'params', 'action'],
  oldBankLoginForm: [],
  oldBankLoginRequest: [],
  oldBankLoginMfaStatus: [],
  signOut: [],
  updateEmail: ['payload', 'action'],
  updatePassword: ['payload', 'action'],
  startRedirectChallenge: ['payload'],
  startRegShieldRegistration: ['bankCode', 'bankId'],
  stopRegShieldRegistration: [],
  completeRegShieldRegistration: ['payload'],
  getTransferringAccounts: []
});
