import { SVGProps } from 'react';

export const HamburgerMenuIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M17.3571 2.57143H0.642822"
      stroke="#1C213C"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="path"
    />
    <path
      d="M17.3571 9H0.642822"
      stroke="#1C213C"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="path"
    />
    <path
      d="M17.3571 15.4286H0.642822"
      stroke="#1C213C"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="path"
    />
  </svg>
);
