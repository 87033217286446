import { ReactNode } from 'react';

interface ProgressBarProps {
  name: string;
  value: number;
  label?: ReactNode;
}

export const ProgressBar = ({ value, label, name }: ProgressBarProps) => {
  if (value < 0 || value > 100) {
    throw new Error('The value prop in ProgressBar must be between 0 and 100.');
  }

  return (
    <div
      className="flex flex-col items-center justify-between gap-[4px]"
      data-testid={`${name}-progress-bar`}
    >
      {label && (
        <span className="gap-[8px] p-2 text-sm leading-6 text-general-80 dark:text-white">
          {label}
        </span>
      )}
      <div
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        className="relative h-2 w-full bg-general-20"
      >
        <div
          data-testid={`${name}-progress-bar-fill`}
          className="absolute left-0 top-0 h-2 bg-primary-100 transition-all duration-300"
          style={{ width: `${value}%` }}
        />
      </div>
    </div>
  );
};
