import { createTheme } from '@mui/material/styles';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { createSelector } from '@reduxjs/toolkit';
import { customColors } from 'theme';

import { Types } from 'actions/app';

import { config } from 'config';

import {
  capitalizeObjectKeys,
  mapMUIOverridesPrefix,
  mapMUIv4OverridesToMUIv5
} from 'utils/object';

export const getTheme = ({ api }, { theme }) => {
  const data = get(api, [Types.GET_TENANT_SETTINGS, 'data', 'mobileAsk', 'tenantVisuals'], {});

  const {
    primary,
    secondary,
    text,
    background = { default: customColors.pureWhite },
    font = {},
    overrides = {},
    ...rest
  } = data;

  const formattedTheme = { ...theme, ...rest };

  formattedTheme.palette = {
    ...theme.palette,
    ...customColors,
    ...data.otherColors,
    secondary: {
      ...theme.palette.secondary,
      ...secondary
    },
    primary: {
      ...theme.palette.primary,
      main: customColors.activeBlue,
      ...primary
    },
    text: {
      ...theme.palette.text,
      ...text
    },
    background: {
      ...theme.palette.background,
      ...background
    }
  };

  formattedTheme.typography = {
    fontSizes: theme.typography.fontSizes,
    fontWeights: theme.typography.fontWeights,
    useNextVariants: true,
    fontFamily: (font.url && font.family) || config.defaultFontFamily
  };

  formattedTheme.header = {
    main: formattedTheme.palette.background.paper,
    contrastText: customColors.doveGrey,
    ...formattedTheme.header
  };

  formattedTheme.progressbar = {
    fontSize: '12px',
    textTransform: 'lowercase',
    contrastText: customColors.doveGrey,
    ...formattedTheme.progressbar
  };

  formattedTheme.transaction = {
    backgroundColor: customColors.veryLightPink,
    ...formattedTheme.transaction
  };

  formattedTheme.hint = {
    main: customColors.lightYellow,
    secondary: customColors.lightGreen,
    buttonColor: theme.primary,
    ...formattedTheme.hint
  };

  const modifiedTenantOverrides = capitalizeObjectKeys(
    mapMUIv4OverridesToMUIv5(mapMUIOverridesPrefix(overrides))
  );

  formattedTheme.components.MuiPickersDay = {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${formattedTheme.palette.primary.main} !important`
        },
        '&.Mui-selected:hover': {
          backgroundColor: `${formattedTheme.palette.primary.main} !important`
        }
      }
    }
  };

  merge(formattedTheme.components, modifiedTenantOverrides);

  return createTheme(formattedTheme);
};

export const themeSelector = createSelector([getTheme], theme => {
  return { theme };
});
