import get from 'lodash/get';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { Types } from 'actions/mandates';
import { RootState } from 'store/store';
import { Balance } from 'models/Balance';
import { TransferStatus } from 'models/TransferStatus';

const TRANSFER_AMOUNT = 0;

export const getTransferBalanceResponseData: Selector<RootState['api'], Balance> = ({ api }) => {
  const response = get(api, [Types.GET_TRANSFER_BALANCE, 'data'], {});
  response.currency = response.currency || 'EUR';
  return response;
};

type TransferDetails = {
  currentBalance: number;
  transferAmount: number;
  remainingTransfer: number;
  currency: string;
};

const transferDetails: Selector<RootState, TransferDetails> = ({ api }) => {
  const { amount, currency } = getTransferBalanceResponseData({ api });

  const difference = amount - TRANSFER_AMOUNT;

  const transferAmount = amount >= TRANSFER_AMOUNT ? TRANSFER_AMOUNT : amount;

  const remainingTransfer = difference <= 0 ? 0 : difference;

  return {
    currentBalance: amount,
    transferAmount,
    remainingTransfer,
    currency
  };
};

const getTransferBalanceStatus: Selector<RootState, TransferStatus['status']> = ({ api }) =>
  get(api, [Types.GET_TRANSFER_STATUS, 'data', 'status'], false);

const getPaymentLocation: Selector<RootState, string | null> = ({ api }) => {
  const isFetching = get(api, [Types.GET_PAYMENT_LOCATION, 'isFetching']);
  if (isFetching) return null;

  const location = get(api, [Types.GET_PAYMENT_LOCATION, 'data', 'location'], null);

  return location ? `${location}&ask_ui=true` : location;
};

const getRedirectChallengePaymentAmount: Selector<RootState, string | null> = ({ api }) =>
  get(api, [Types.GET_PAYMENT_LOCATION, 'data', 'selectedPaymentAmount'], null);

export const transferBalanceSelector = createSelector(
  [
    getTransferBalanceResponseData,
    transferDetails,
    getTransferBalanceStatus,
    getPaymentLocation,
    getRedirectChallengePaymentAmount
  ],
  (
    transferData,
    transferDetails,
    transferStatus,
    paymentLocation,
    redirectChallengePaymentAmount
  ) => ({
    transferData,
    transferDetails,
    transferStatus,
    oneTimePaymentLocation: paymentLocation,
    redirectChallengePaymentAmount
  })
);
