import { SVGProps } from 'react';

export const SuccessCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="69" height="69" viewBox="0 0 69 69" fill="none" {...props}>
    <path
      d="M34.0291 67.0583C52.2706 67.0583 67.0583 52.2706 67.0583 34.0291C67.0583 15.7876 52.2706 1 34.0291 1C15.7876 1 1 15.7876 1 34.0291C1 52.2706 15.7876 67.0583 34.0291 67.0583Z"
      fill="#86EADE"
      stroke="#0ED4BD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0292 48.7087C42.1365 48.7087 48.7088 42.1365 48.7088 34.0291C48.7088 25.9218 42.1365 19.3495 34.0292 19.3495C25.9219 19.3495 19.3496 25.9218 19.3496 34.0291C19.3496 42.1365 25.9219 48.7087 34.0292 48.7087Z"
      fill="#0ED4BD"
      stroke="#0ED4BD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6428 35.9929L29.1528 40.5057C29.2713 40.6596 29.423 40.7847 29.5967 40.8715C29.7704 40.9584 29.9615 41.0047 30.1557 41.0071C30.3467 41.0094 30.5359 40.969 30.7093 40.8889C30.8828 40.8088 31.0363 40.6911 31.1585 40.5443L42.3571 26.9929"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
