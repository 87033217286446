import { createContext } from 'react';

import { TenantSettings } from 'actions/app';

interface AppContextType {
  isDrawerMenuOpen: boolean;
  isAuthenticated: boolean;
  isRegisteredFromLink: boolean;
  toggleDrawerMenu: () => void;
  tenantSettings: TenantSettings;
  fullName: string | null;
  appMenuHidden: boolean;
  isManualSwitch: boolean;
  isAuthorized: boolean;
  clearBankAndGoToPreviousPage: () => void;
  showBackButton: boolean;
}

export const AppBarContext = createContext<AppContextType | Record<string, never>>({});
