import { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive';
  icon?: ReactElement;
  size?: 'small' | 'large';
  iconPosition?: 'left' | 'right';
  name?: string;
}

export const Button = ({
  children,
  icon,
  iconPosition = 'left',
  variant = 'primary',
  className,
  disabled,
  size = 'large',
  name = 'button',
  type = 'button',
  ...rest
}: ButtonProps) => {
  const btnClass = twMerge(
    clsx(
      'group flex h-[48px] flex-row items-center gap-x-2 rounded-md p-3 font-robotoFlex text-base font-bold leading-4 disabled:cursor-not-allowed',
      {
        'bg-primary-100 dark:bg-white dark:text-primary-100 text-white': variant === 'primary',
        'hover:bg-primary-hover dark:hover:bg-primary-20': variant === 'primary',
        'active:bg-primary-80': variant === 'primary',
        'hover:bg-primary-disabled active:bg-primary-disabled bg-primary-disabled text-general-100 dark:hover:bg-primary-disabled dark:active:bg-primary-disabled dark:bg-primary-disabled dark:text-general-100':
          disabled && variant === 'primary',
        'border border-2 border-primary-100 dark:border-white bg-none text-primary-100 dark:text-white':
          variant === 'secondary',
        'hover:border-primary-hover hover:text-primary-hover dark:hover:border-primary-70 dark:hover:text-primary-70':
          variant === 'secondary',
        'active:border-primary-80 active:text-primary-80': variant === 'secondary',
        [`text-primary-disabled dark:text-general-40 dark:border-general-40 border-primary-disabled active:border-primary-disabled 
          dark:active:border-general-40 active:text-primary-disabled dark:active:text-general-80 hover:border-primary-disabled 
          hover:text-primary-disabled dark:hover:border-general-40 dark:hover:text-general-40`]:
          disabled && variant === 'secondary',
        'border-none bg-none text-primary-100 dark:text-white hover:text-primary-hover dark:hover:text-primary-70 active:text-primary-hover dark:active:text-primary-70 underline':
          variant === 'tertiary',
        'text-primary-disabled hover:text-primary-disabled dark:text-general-40 dark:hover:text-general-40':
          disabled && variant === 'tertiary',
        'bg-red-90 hover:bg-red-70 active:bg-red-70 text-white': variant === 'destructive',
        'bg-primary-disabled hover:bg-primary-disabled active:bg-primary-disabled text-general-100':
          disabled && variant === 'destructive',
        'flex-row-reverse': iconPosition === 'right',
        'h-[38px] text-sm leading-[14px]': size === 'small'
      },
      'transition-all duration-200',
      className
    )
  );

  return (
    <button className={btnClass} disabled={disabled} name={name} type={type} {...rest}>
      {icon && <span data-testid={`${name}-icon`}>{icon}</span>}
      {children}
    </button>
  );
};
