import get from 'lodash/get';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { Types } from 'actions/closeAccount';
import { RootState } from 'store/store';
import { getTransferringAccounts } from 'selectors/accountsSelector';

import { directDebitProgress } from './directDebitsSelectors';
import { incomingPaymentsProgress } from './incomingPaymentsSelectors';
import { tenantSettingsResponseData } from './tenantSettingsSelector';
import { getTransferBalanceResponseData } from './transferBalanceSelector';

export const getCloseAccountStatus: Selector<RootState, boolean> = ({ api }) =>
  get(api, [Types.GET_CLOSE_ACCOUNT_STATUS, 'data']);

export const closeAccountSelector = createSelector(
  [
    getTransferBalanceResponseData,
    directDebitProgress,
    incomingPaymentsProgress,
    tenantSettingsResponseData,
    getCloseAccountStatus,
    getTransferringAccounts
  ],
  (
    transferData,
    directDebitProgress,
    incomingPaymentsProgress,
    tenantSettings,
    status,
    transferringAccounts
  ) => {
    return {
      transferData,
      status,
      transferringAccounts,
      switchItemProps: {
        tenantSettings,
        directDebitProgress,
        incomingPaymentsProgress,
        transferBalance: {
          amount: transferData.amount,
          currency: transferData.currency
        }
      },
      // TODO: THIS TEMPORARY FIX WILL BE REMOVED ONCE https://finleap-connect.atlassian.net/browse/KIT2-834 IS RESOLVED !!!!
      isOldBankCloseAccountDisabled: ['INGDDEFFXXX', 'INGBDEFFXXX', 'INGBATWW'].includes(
        transferData?.oldBic
      )
    };
  }
);
