import { createSelector } from '@reduxjs/toolkit';

import { billsProgress } from 'selectors/overviewSelector';

import { incomingPaymentsProgress } from './incomingPaymentsSelectors';

export const sidebarNavigationSelector = createSelector(
  [billsProgress, incomingPaymentsProgress],
  (billsProgress, incomingPaymentsProgress) => {
    return {
      billsCount: billsProgress.total - billsProgress.done,
      incomingPaymentsCount: incomingPaymentsProgress.total - incomingPaymentsProgress.done
    };
  }
);
