import { SVGProps } from 'react';

export const SunIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g
        id="interface-lighting-brightness-4--bright-adjust-brightness-adjustment-sun-raise-controls-dot"
        clipPath="url(#clip0_1872_413)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M6.00007 8.78578C7.53858 8.78578 8.78578 7.53858 8.78578 6.00007C8.78578 4.46156 7.53858 3.21436 6.00007 3.21436C4.46156 3.21436 3.21436 4.46156 3.21436 6.00007C3.21436 7.53858 4.46156 8.78578 6.00007 8.78578Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6 0.428589V1.28573"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M6 10.7144V11.5715"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M11.5715 6H10.7144"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_5"
            d="M1.28585 6H0.428711"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M9.94304 2.05713L9.33447 2.6657"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_7"
            d="M2.6657 9.33435L2.05713 9.94292"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_8"
            d="M9.94304 9.94292L9.33447 9.33435"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_9"
            d="M2.6657 2.6657L2.05713 2.05713"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1872_413">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
