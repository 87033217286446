import { MouseEvent } from 'react';
import { toast, Slide } from 'react-toastify';
import { ToastContentProps, ToastOptions } from 'react-toastify/dist/types';
import clsx from 'clsx';
import { CloseButtonProps } from 'react-toastify/dist/components';
import noop from 'lodash/noop';

import { CloseIcon } from 'components/Icons/V2/CloseIcon';
import { MarkDown } from 'components/MarkDown';
import { Button } from 'components/_V2/Button/Button';

import 'react-toastify/dist/ReactToastify.min.css';
import 'styles/toast.css';

export type ToastType = 'SUCCESS' | 'INFO' | 'ERROR';

export interface TriggerToast {
  message: string;
  type: ToastType;
  toastOptions?: ToastOptions;
  callbackButtonOptions?: {
    enabled: boolean;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    label: string;
  };
}

export const triggerToast = ({
  message,
  type,
  toastOptions,
  callbackButtonOptions = { enabled: false, onClick: noop, label: '' }
}: TriggerToast) => {
  const Content = ({ toastProps: { toastId } }: ToastContentProps) => (
    <div
      data-testid={`toast-content-${toastId}`}
      className="flex flex-col items-center justify-center text-sm sm:flex-row"
    >
      <MarkDown
        data-testid="toast-message"
        className="text-base font-bold text-general-80"
        htmlTag="div"
        text={message}
      />
      {callbackButtonOptions.enabled && (
        <Button
          variant="tertiary"
          onClick={callbackButtonOptions.onClick}
          data-testid="toast-callback-button"
          className="h-fit min-w-fit py-0 dark:text-primary-100 dark:hover:text-primary-hover dark:active:text-primary-hover"
        >
          {callbackButtonOptions.label}
        </Button>
      )}
    </div>
  );

  const CloseButton = ({ closeToast }: CloseButtonProps) => (
    <button
      type="button"
      aria-label="toast close button"
      data-testid="toast-close-button"
      className="pr-3"
      onClick={closeToast}
    >
      <CloseIcon />
    </button>
  );

  const containerClassName = clsx('!mb-0 !rounded-none !shadow-none', {
    '!bg-green-30 !border !border-green-100': type === 'SUCCESS',
    '!bg-primary-10 !border !border-primary-40': type === 'INFO',
    '!bg-red-30 !border !border-red-70': type === 'ERROR'
  });

  return toast(Content, {
    draggable: false,
    className: containerClassName,
    autoClose: 5000,
    containerId: !toastOptions?.toastId ? 'global-toast' : undefined,
    closeButton: CloseButton,
    hideProgressBar: true,
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    transition: Slide,
    ...toastOptions
  });
};
