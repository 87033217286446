import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const closeAccountRoutes: Record<string, Route> = {
  closeAccount: {
    id: 'closeAccount',
    title: 'navigationCloseAccount',
    path: '/close-account',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./CloseAccountTransferContainer')
    })
  }
};

export default closeAccountRoutes;
