import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import OnboardingProgressBar from 'components/OnboardingProgressBar/OnboardingProgressBarContainer';

import { PublicRouteLayout } from './BaseStyled';

interface AppLayoutBuilderProps {
  isProtectedRouteLayout: boolean;
  isAuthenticated: boolean;
}

export const AppLayoutBuilder = ({
  children,
  isProtectedRouteLayout
}: PropsWithChildren<AppLayoutBuilderProps>) => {
  const location = useLocation();

  if (isProtectedRouteLayout) {
    return (
      <div className="min-h-screen bg-white p-6 dark:bg-general-100 md:ml-[300px]">{children}</div>
    );
  }

  const isOldBankPage = location.pathname.startsWith('/old-bank');

  return (
    <PublicRouteLayout>
      <OnboardingProgressBar />
      <div
        className={clsx('bg-white dark:bg-general-100', {
          'flex justify-center p-4 sm:p-20': !isOldBankPage
        })}
        data-testid="public-layout-container"
      >
        {children}
      </div>
    </PublicRouteLayout>
  );
};
