import { SVGProps } from 'react';

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="interface-delete-1--remove-add-button-buttons-delete" clipPath="url(#clip0_1966_7358)">
        <g id="Group">
          <path
            id="Vector"
            d="M17.3574 0.642822L0.643066 17.3571"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_2"
            d="M0.643066 0.642822L17.3574 17.3571"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1966_7358">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
