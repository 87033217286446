import React, { InputHTMLAttributes, ForwardRefRenderFunction, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { FieldProps } from 'formik';

export interface RadioGroupOption {
  label: string;
  value: number | string;
}

export type RadioGroupProps = InputHTMLAttributes<HTMLInputElement> &
  Partial<FieldProps['field']> & {
    name: string;
    id: string;
    label?: string;
    disabled?: boolean;
    errorText?: string;
    options: RadioGroupOption[];
    isInline?: boolean;
    selectedValue?: InputHTMLAttributes<HTMLInputElement>['value'];
    renderOptionLabel?: (option: RadioGroupOption, index: number) => ReactNode;
  };

const RadioGroupComponent: ForwardRefRenderFunction<HTMLFieldSetElement, RadioGroupProps> = (
  {
    name,
    id,
    errorText = '',
    disabled = false,
    options,
    isInline = false,
    label,
    renderOptionLabel,
    value: selectedValue,
    ...inputProps
  },
  ref
) => {
  const hasError = Boolean(errorText);

  return (
    <fieldset
      data-testid={`${name}-radiogroup`}
      aria-invalid={hasError}
      role="radiogroup"
      className="flex flex-col"
      ref={ref}
    >
      <div
        className={twMerge(
          clsx('flex flex-col items-start gap-y-6', {
            'flex-row gap-x-2 gap-y-0 items-center': isInline
          })
        )}
      >
        {label && (
          <legend className="text-sm text-general-80 dark:text-white" data-testid={`${name}-label`}>
            {label}
          </legend>
        )}
        {options.map(({ label: optionLabel, value: optionValue }, index) => (
          <div
            key={`${name}-${index}`}
            className={clsx('flex flex-col', {
              'cursor-not-allowed': disabled,
              'w-full': !isInline
            })}
          >
            <label
              className={clsx('flex cursor-pointer items-center gap-x-3', { 'w-full': !isInline })}
              htmlFor={`${id}-${index}`}
            >
              <span className="flex items-center">
                <input
                  {...inputProps}
                  type="radio"
                  name={name}
                  id={`${id}-${index}`}
                  data-testid={`${name}-radio-input-${index}`}
                  data-has-error={hasError}
                  className={twMerge(
                    clsx(
                      'peer size-[20px] cursor-pointer appearance-none rounded-full border border-general-80 outline-none',
                      'dark:border-general-20',
                      'checked:border-[6px] checked:border-primary-100 checked:hover:ring-1 checked:hover:ring-primary-hover [&:not(:checked)]:hover:border-2 [&:not(:checked)]:hover:border-primary-hover',
                      'dark:checked:border-white dark:checked:bg-primary-100 dark:checked:hover:ring-1 dark:checked:hover:ring-general-20 dark:[&:not(:checked)]:hover:border-2 dark:[&:not(:checked)]:hover:border-general-20',
                      'transition-all duration-200 ease-linear',
                      {
                        'border-red-90 border-2 checked:border-red-90 hover:border-red-90 [&:not(:checked)]:hover:border-2 dark:border-red-40 dark:border-2 dark:checked:border-red-40 dark:hover:border-red-40 dark:[&:not(:checked)]:hover:border-2':
                          errorText,
                        [`border-primary-disabled checked:border-primary-disabled checked:hover:ring-0 dark:checked:hover:ring-0 
                      hover:border-primary-disabled cursor-not-allowed [&:not(:checked)]:hover:border-1 dark:[&:not(:checked)]:hover:border-1 
                      [&:not(:checked)]:hover:border-primary-disabled bg-primary-disabled-light dark:bg-general-40 dark:checked:border-primary-disabled`]:
                          disabled
                      }
                    )
                  )}
                  disabled={disabled}
                  value={optionValue}
                  checked={selectedValue === optionValue}
                />
              </span>
              {renderOptionLabel ? (
                renderOptionLabel({ label: optionLabel, value: optionValue }, index)
              ) : (
                <span
                  className="text-sm text-general-80 peer-checked:text-general-100 dark:text-white dark:peer-checked:font-medium dark:peer-checked:text-white"
                  data-testid={`${name}-radio-label-${index}`}
                >
                  {optionLabel}
                </span>
              )}
            </label>
          </div>
        ))}
      </div>
      <small
        role="alert"
        data-testid={`${name}-error-text`}
        className="mt-1 inline-block h-4 text-red-100 dark:text-red-40"
      >
        {errorText}
      </small>
    </fieldset>
  );
};

export const RadioGroup = forwardRef(RadioGroupComponent);
