import React from 'react';
import { ToastContainer } from 'react-toastify';
import { t } from 'locale';

import { LocaleKey } from 'locale/types';

import { config } from 'config';

import { localeKeys } from 'enums/localeKeys';

import { useIsUpdated } from 'hooks/useIsUpdated';

import { triggerToast } from 'utils/triggerToast';

import { ErrorToastPropsFromRedux } from './ErrorToastContainer';

export const ErrorToast = ({
  content,
  reload,
  uuid,
  networkUnavailable,
  redirected,
  clearError
}: ErrorToastPropsFromRedux) => {
  const onTriggerClosed = () => {
    setTimeout(() => {
      clearError();
    }, config.errorDialogCloseClearErrorDelay);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const networkErrorReload = () => {
    setTimeout(() => {
      if (!window.navigator.onLine) {
        clearError();
        openToast();
      } else {
        reloadPage();
      }
    }, config.networkConnectionErrorReloadDelay);
  };

  useIsUpdated(() => {
    if (content || redirected) {
      openToast();
    }
  }, uuid);

  const renderMessage = (messages: { message: LocaleKey }[] = []) => {
    return messages.map(({ message }) => t(message)).join(' ');
  };

  const toastOptions = {
    containerId: 'error-toasts',
    onClose: onTriggerClosed
  };

  const openToast = () => {
    triggerToast({
      message: renderMessage(content),
      type: 'ERROR',
      toastOptions,
      ...(reload
        ? {
            callbackButtonOptions: {
              enabled: true,
              onClick: () => {
                networkUnavailable ? networkErrorReload() : reloadPage();
              },
              label: t(localeKeys.errorReloadButton)
            }
          }
        : {})
    });
  };

  return <ToastContainer position="top-center" containerId={toastOptions?.containerId} />;
};
