import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { noop } from 'lodash';

const initialState: {
  title: string;
  children?: JSX.Element | null;
  showCancel?: boolean;
  confirmLabel?: string;
  isSuccess?: boolean;
  isConfirmDestructive?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
} = {
  title: '',
  children: null,
  showCancel: false,
  confirmLabel: 'OK',
  isSuccess: false,
  isConfirmDestructive: false,
  onConfirm: noop,
  onCancel: noop
};

export const modalSlice = createSlice({
  name: 'globalModal',
  initialState,
  reducers: {
    triggerModal: (
      state,
      action: PayloadAction<{
        title: string;
        children: JSX.Element;
        options?: {
          confirmLabel?: string;
          showCancel?: boolean;
          isSuccess?: boolean;
          isConfirmDestructive?: boolean;
          onConfirm?: () => void;
          onCancel?: () => void;
        };
      }>
    ) => {
      const { title, children, options } = action.payload;

      state.title = title;
      state.children = children;
      state.confirmLabel = options?.confirmLabel ?? initialState.confirmLabel;
      state.showCancel = options?.showCancel ?? initialState.showCancel;
      state.isSuccess = options?.isSuccess ?? initialState.isSuccess;
      state.isConfirmDestructive =
        options?.isConfirmDestructive ?? initialState.isConfirmDestructive;
      state.onConfirm = options?.onConfirm ?? initialState.onConfirm;
      state.onCancel = options?.onCancel ?? initialState.onCancel;
    },
    clearModal: () => {
      return initialState;
    }
  },
  selectors: {
    getModalsSelector: state => {
      const { title, children, ...otherProps } = state;
      if (title) {
        return {
          title,
          children,
          options: {
            ...otherProps
          }
        };
      }
    }
  }
});

export const { triggerModal, clearModal } = modalSlice.actions;
export const { getModalsSelector } = modalSlice.selectors;
