import { Route } from 'slices/routes';
import React, { useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { t } from 'locale';

import { Button } from 'components/_V2/Button/Button';
import { AlertCircleIcon } from 'components/Icons/V2/AlertCircleIcon';
import { SidebarThemeToggle } from 'components/_V2/SidebarNavigation/SidebarThemeToggle';
import {
  SidebarNavigationItem,
  SidebarNavigationItemProps
} from 'components/_V2/SidebarNavigation/SidebarNavigationItem';
import { Language } from 'locale/reducer';
import { AvatarIcon } from 'components/Icons/V2/AvatarIcon';
import { ArrowDownIcon } from 'components/Icons/V2/ArrowDownIcon';
import { CloseIcon } from 'components/Icons/V2/CloseIcon';
import { SidebarLanguageSelection } from 'components/_V2/SidebarNavigation/SidebarLanguageSelection';

import { config } from 'config';

import { localeKeys } from 'enums/localeKeys';

import { useIsUpdated } from 'hooks/useIsUpdated';

export type NavigationItem = Pick<
  SidebarNavigationItemProps,
  'title' | 'IconComponent' | 'dataId' | 'routeTo' | 'count'
>;

export type ManageAccountItem = Omit<NavigationItem, 'count'>;

interface LanguageOption {
  label: string;
  value: Language;
}

export interface SidebarNavigationProps {
  navigationItems: NavigationItem[];
  manageAccountItems: ManageAccountItem[];
  onClickItem: (path: Route['path']) => void;
  activePath: Route['path'];
  languages: LanguageOption[];
  isMobile: boolean;
  onClickClose: VoidFunction;
  selectedLanguage: Language;
  onChangeLanguage: (language: Language) => void;
  isAuthenticated: boolean;
  tenantLogoUrl?: string | null;
}

export const SidebarNavigation = ({
  navigationItems,
  manageAccountItems,
  onClickItem,
  activePath,
  languages,
  isMobile,
  onClickClose,
  selectedLanguage,
  onChangeLanguage,
  isAuthenticated,
  tenantLogoUrl
}: SidebarNavigationProps) => {
  const [isManageAccountOpen, setIsManageAccountOpen] = useState(false);

  useIsUpdated(() => {
    setIsManageAccountOpen(false);
  }, activePath);

  const { apis, apiBaseUrl } = config;

  return (
    <div
      className={twMerge(
        clsx(
          'flex h-svh min-w-[290px] flex-col justify-between overflow-y-auto bg-primary-5 p-4 dark:bg-general-90'
        )
      )}
    >
      <div>
        {!isMobile && (
          <div className="mb-12">
            {Boolean(tenantLogoUrl) && (
              <img
                src={tenantLogoUrl as string}
                alt="logo"
                className="h-5 w-[71px]"
                data-testid="tenant-logo"
              />
            )}
          </div>
        )}
        {isMobile && (
          <>
            <div
              className={twMerge(
                clsx('flex items-center justify-between', {
                  'justify-end': !isAuthenticated
                })
              )}
              data-testid="sidebar-manage-account-header"
            >
              {isAuthenticated && (
                <button
                  type="button"
                  className="flex items-center gap-x-3"
                  onClick={() => setIsManageAccountOpen(!isManageAccountOpen)}
                  data-testid="sidebar-manage-account-button"
                >
                  <AvatarIcon data-testid="sidebar-manage-account-avatar-icon" />
                  <span
                    className="text-general-100 dark:text-white"
                    data-testid="sidebar-manage-account-button-text"
                  >
                    {t(localeKeys.manageAccount)}
                  </span>
                  <ArrowDownIcon
                    className={clsx('transition-all duration-300 dark:[&_.path]:stroke-white', {
                      'rotate-180': isManageAccountOpen
                    })}
                    data-testid="sidebar-manage-account-arrow-icon"
                  />
                </button>
              )}
              <button
                aria-label={t(localeKeys.closeButton)}
                onClick={onClickClose}
                type="button"
                data-testid="sidebar-close-button"
              >
                <CloseIcon className="dark:[&_.path]:stroke-white" />
              </button>
            </div>
            <div className="h-6" />
          </>
        )}

        {isManageAccountOpen ? (
          <div className="flex flex-col" data-testid="sidebar-manage-account-section">
            <nav>
              <span
                className="text-sm text-general-70 dark:text-general-30"
                data-testid="sidebar-manage-account-text"
              >
                {t(localeKeys.manageAccount)}
              </span>
              <div className="h-2" />
              <ul className="flex w-full flex-col gap-y-2">
                {manageAccountItems.map(({ IconComponent, title, dataId, routeTo }, index) => {
                  return (
                    <SidebarNavigationItem
                      key={`${title}-${index}`}
                      title={title}
                      IconComponent={IconComponent}
                      dataId={dataId}
                      routeTo={routeTo}
                      onClick={onClickItem}
                      activePath={activePath}
                    />
                  );
                })}
              </ul>
              <div className="h-6" />
              <span
                className="text-sm text-general-70 dark:text-general-30"
                data-testid="sidebar-change-language-text"
              >
                {t(localeKeys.navigationChangeLanguage)}
              </span>
              <div className="h-2" />
              <div className="px-4 py-2.5">
                <SidebarLanguageSelection
                  languages={languages}
                  selectedLanguage={selectedLanguage}
                  onChangeLanguage={onChangeLanguage}
                />
              </div>
            </nav>
          </div>
        ) : (
          <div className="flex flex-col" data-testid="sidebar-main-section">
            <nav>
              <ul className="flex w-full flex-col gap-y-2">
                {navigationItems.map(({ IconComponent, title, dataId, routeTo, count }, index) => {
                  return (
                    <SidebarNavigationItem
                      key={`${title}-${index}`}
                      title={title}
                      IconComponent={IconComponent}
                      dataId={dataId}
                      routeTo={routeTo}
                      count={count}
                      activePath={activePath}
                      onClick={onClickItem}
                    />
                  );
                })}
              </ul>
              <div className="h-6" />
              <span
                className="text-sm text-general-70 dark:text-general-30"
                data-testid="sidebar-other-information-text"
              >
                {t(localeKeys.otherInformation)}
              </span>
              <div className="h-2" />
              <SidebarNavigationItem
                title={t(localeKeys.helpCentre)}
                dataId="sidebar-item-help-centre"
                IconComponent={AlertCircleIcon}
                routeTo="/help-centre"
                activePath={activePath}
                onClick={onClickItem}
                htmlTag="div"
              />
            </nav>
            <div className="h-6" />
            {!isAuthenticated && (
              <>
                <span
                  className="text-sm text-general-70 dark:text-general-30"
                  data-testid="sidebar-change-language-text"
                >
                  {t(localeKeys.navigationChangeLanguage)}
                </span>
                <div className="h-2" />
                <div className="px-4 py-2.5">
                  <SidebarLanguageSelection
                    languages={languages}
                    selectedLanguage={selectedLanguage}
                    onChangeLanguage={onChangeLanguage}
                  />
                </div>
              </>
            )}
            <span
              className="text-sm text-general-70 dark:text-general-30"
              data-testid="sidebar-appearance-text"
            >
              {t(localeKeys.appearance)}
            </span>
            <div className="h-2" />
            <SidebarThemeToggle
              lightLabel={t(localeKeys.lightMode)}
              darkLabel={t(localeKeys.darkMode)}
            />
            <div className="h-12" />
            {isAuthenticated && (
              <>
                <a href={`${apiBaseUrl}/${apis.letterSummary}`} download>
                  <Button variant="secondary" className="w-full">
                    <span className="w-full text-center" data-testid="summary-pdf-button">
                      {t(localeKeys.downloadSummary)}
                    </span>
                  </Button>
                </a>
                <div className="h-12" />
              </>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col">
        <p
          className="break-words text-xs text-general-70 dark:text-white"
          data-testid="sidebar-footer-text"
        >
          {t(localeKeys.footerEncryption)} <br />
          {t(localeKeys.footerSecureServer)} <br />
          {t(localeKeys.footerAudits)}
        </p>
        <div className="h-6" />
        <img
          src="/company_logo.png"
          alt="logo"
          className="h-5 w-[71px]"
          data-testid="company-logo"
        />
        <div className="h-2" />
        <p
          className="text-xs text-general-70 dark:text-white"
          data-testid="sidebar-footer-powered-by-text"
        >
          Powered by Qwist GmbH <br /> ASK V2.0
        </p>
      </div>
    </div>
  );
};
