import { Language } from 'locale/reducer';
import { LocaleKey } from 'locale/types';

import { localeKeys } from 'enums/localeKeys';
import { countryCodes } from 'enums/keys';

export const languageTranslationsMap: Record<string, { title: LocaleKey; subtitle: LocaleKey }> = {
  english: {
    title: localeKeys.languageMenuEnglish,
    subtitle: localeKeys.languageMenuEnglishNative
  },
  german: {
    title: localeKeys.languageMenuGerman,
    subtitle: localeKeys.languageMenuGermanNative
  },
  spanish: {
    title: localeKeys.languageMenuSpanish,
    subtitle: localeKeys.languageMenuSpanishNative
  },
  catalan: {
    title: localeKeys.languageMenuCatalan,
    subtitle: localeKeys.languageMenuCatalanNative
  }
};

export const countryCodeConvert = (value: string): Language => {
  return (value === countryCodes.en ? countryCodes.gb : value) as Language;
};
