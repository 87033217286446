import { SVGProps } from 'react';

export const WalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="money-wallet--money-payment-finance-wallet" clipPath="url(#clip0_1857_1709)">
        <g id="Group">
          <path
            id="Vector"
            d="M15.4285 9.64303V7.0716C15.4285 6.73061 15.2931 6.40358 15.052 6.16247C14.8108 5.92135 14.4838 5.78589 14.1428 5.78589H1.92854C1.58754 5.78589 1.26052 5.92135 1.0194 6.16247C0.778281 6.40358 0.642822 6.73061 0.642822 7.0716V16.0716C0.642822 16.4126 0.778281 16.7396 1.0194 16.9807C1.26052 17.2219 1.58754 17.3573 1.92854 17.3573H14.1428C14.4838 17.3573 14.8108 17.2219 15.052 16.9807C15.2931 16.7396 15.4285 16.4126 15.4285 16.0716V12.8573"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_2"
            d="M4.93701 2.57149L12.227 0.668629C12.3089 0.645549 12.3947 0.639305 12.4791 0.650275C12.5635 0.661246 12.6448 0.689203 12.7181 0.732459C12.7914 0.775715 12.8551 0.833374 12.9055 0.901955C12.9559 0.970536 12.9919 1.04862 13.0113 1.13149L13.3713 2.57149"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_3"
            d="M16.7143 9.64282H13.5C13.145 9.64282 12.8572 9.93064 12.8572 10.2857V12.2143C12.8572 12.5693 13.145 12.8571 13.5 12.8571H16.7143C17.0694 12.8571 17.3572 12.5693 17.3572 12.2143V10.2857C17.3572 9.93064 17.0694 9.64282 16.7143 9.64282Z"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_1709">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
