import { SVGProps } from 'react';

export const HomeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="interface-home-3--home-house-map-roof" clipPath="url(#clip0_1857_1678)">
        <g id="Group">
          <path
            id="Vector"
            d="M0.642822 8.99997L8.99997 0.642822L17.3571 8.99997"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_2"
            d="M3.21436 10.9285V17.357H14.7858V10.9285"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1857_1678">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
