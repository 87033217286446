import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  isDrawerMenuOpen: boolean;
} = {
  isDrawerMenuOpen: false
};

export const drawerMenuSlice = createSlice({
  name: 'drawerMenu',
  initialState,
  reducers: {
    toggleDrawerMenu: state => {
      state.isDrawerMenuOpen = !state.isDrawerMenuOpen;
    }
  },
  selectors: {
    getIsDrawerMenuOpen: state => {
      return state.isDrawerMenuOpen;
    }
  }
});

export const { toggleDrawerMenu } = drawerMenuSlice.actions;
export const { getIsDrawerMenuOpen } = drawerMenuSlice.selectors;
