import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { triggerModal } from 'slices/modal';
import { routes } from 'routes';
import { useState } from 'react';
import { t } from 'locale';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { getIsDrawerMenuOpen, toggleDrawerMenu } from 'slices/drawerMenu';

import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';
import { appMenuSelector, appSelector } from 'selectors/appSelectors';
import { RootState } from 'store/store';
import { Creators as LocaleCreators, GetLocale } from 'locale/actions';
import { Creators as AccountCreators } from 'actions/account';
import { languageSelector, localeSelector } from 'selectors/localeSelector';
import { HomeIcon } from 'components/Icons/V2/HomeIcon';
import { CreditCardIcon } from 'components/Icons/V2/CreditCardIcon';
import { WalletIcon } from 'components/Icons/V2/WalletIcon';
import { AtmCardIcon } from 'components/Icons/V2/AtmCardIcon';
import { DeleteCircleIcon } from 'components/Icons/V2/DeleteCircleIcon';
import {
  ManageAccountItem,
  NavigationItem,
  SidebarNavigation
} from 'components/_V2/SidebarNavigation/SidebarNavigation';
import { UserProfileIcon } from 'components/Icons/V2/UserProfileIcon';
import { SignOutIcon } from 'components/Icons/V2/SignOutIcon';
import { UserIcon } from 'components/Icons/V2/UserIcon';
import { routeSelector } from 'selectors/routeSelector';
import { sidebarNavigationSelector } from 'selectors/sidebarNavigationSelector';

import { localeKeys } from 'enums/localeKeys';

import { useIsUpdated } from 'hooks/useIsUpdated';

import { isPublicRoute } from 'utils/routes';

interface NavigationProps extends NavigationContainerPropsFromRedux {
  isAuthenticated: boolean;
  isRegisteredFromLink: boolean;
}

const Navigation = ({
  isAuthenticated,
  isRegisteredFromLink,
  tenantSettings,
  toggleDrawerMenu,
  isManualSwitch,
  getLocale,
  currentLanguage,
  languages,
  deleteUser,
  signOut,
  isDrawerMenuOpen,
  triggerModal,
  billsCount,
  incomingPaymentsCount
}: NavigationProps) => {
  const history = useHistory();
  const isAuthorized = isAuthenticated && !isPublicRoute();
  const [isDrawerMenuContentVisible, setIsDrawerMenuContentVisible] = useState(isDrawerMenuOpen);

  useIsUpdated(() => {
    if (isDrawerMenuOpen) {
      setIsDrawerMenuContentVisible(true);
    } else {
      setTimeout(() => {
        setIsDrawerMenuContentVisible(false);
      }, 300);
    }
  }, isDrawerMenuOpen);

  const { disableBalanceTransfer, disableCloseAccount, magicLinkSettings, logo } = tenantSettings;

  const handleDeleteAccount = () => {
    triggerModal({
      title: t(localeKeys.deleteUserTitle),
      children: <div>{t(localeKeys.deleteUserMessage)}</div>,
      options: {
        confirmLabel: t(localeKeys.deleteText),
        onConfirm: () => {
          deleteUser();
          toggleDrawerMenu();
        },
        isConfirmDestructive: true,
        showCancel: true
      }
    });
  };
  const handleSignOut = () => {
    triggerModal({
      title: t(localeKeys.signOutTitle),
      children: <div>{t(localeKeys.signOutMessage)}</div>,
      options: {
        confirmLabel: t(localeKeys.signOutContinueButton),
        onConfirm: () => {
          signOut();
          toggleDrawerMenu();
        },
        showCancel: true
      }
    });
  };

  const navigationItems = [
    {
      title: t(localeKeys.appMenuOverviewText),
      routeTo: routes.overview.path,
      IconComponent: HomeIcon,
      dataId: 'sidebar-item-overview'
    },
    {
      title: t(localeKeys.appMenuMoveMyBills),
      routeTo: routes.bills.path,
      IconComponent: CreditCardIcon,
      dataId: 'sidebar-item-bills',
      count: billsCount
    },
    {
      title: t(localeKeys.incomingPaymentsText),
      routeTo: routes.incomingPayments.path,
      IconComponent: WalletIcon,
      dataId: 'sidebar-item-incoming-payments',
      count: incomingPaymentsCount
    },
    !disableBalanceTransfer &&
      !isManualSwitch && {
        title: t(localeKeys.transferBalanceText),
        routeTo: routes.transferBalance.path,
        IconComponent: AtmCardIcon,
        dataId: 'sidebar-item-transfer-balance'
      },
    !disableCloseAccount && {
      title: t(localeKeys.closeAccountText),
      routeTo: routes.closeAccount.path,
      IconComponent: DeleteCircleIcon,
      dataId: 'sidebar-item-close-account'
    }
  ].filter(Boolean) as NavigationItem[];

  const allowEditingPersonalData = isRegisteredFromLink
    ? magicLinkSettings.allowEditingPersonalData
    : true;

  const manageAccountItems = [
    allowEditingPersonalData && {
      title: t(localeKeys.appMenuPersonalDetailsText),
      routeTo: routes.personalDetails.path,
      IconComponent: UserProfileIcon
    },
    {
      title: t(localeKeys.appMenuDeleteUser),
      routeTo: 'deleteAccount',
      IconComponent: DeleteCircleIcon
    },
    {
      title: t(localeKeys.appMenuSignOut),
      routeTo: 'signOut',
      IconComponent: SignOutIcon
    }
  ].filter(Boolean) as ManageAccountItem[];

  const isSignedOutItems: NavigationItem[] = [
    {
      title: t(localeKeys.loginButtonText),
      routeTo: routes.login.path,
      IconComponent: UserIcon,
      dataId: 'login'
    }
  ];

  const onClickItem = (path: string) => {
    if (path === 'deleteAccount') {
      handleDeleteAccount();
    } else if (path === 'signOut') {
      handleSignOut();
    } else {
      history.push(path);
      toggleDrawerMenu();
    }
  };

  const changeLanguage = (language: string) => {
    getLocale(language);
    setDocumentLang(language);
  };

  const setDocumentLang = (language: string) => {
    document.documentElement.lang = language;
  };

  return (
    <>
      {isAuthorized && (
        <div className="left-0 top-0 z-[1000] hidden h-screen w-[300px] md:fixed md:block">
          <SidebarNavigation
            navigationItems={navigationItems}
            manageAccountItems={manageAccountItems}
            onClickItem={onClickItem}
            activePath={history.location.pathname}
            languages={languages}
            isMobile={false}
            onClickClose={toggleDrawerMenu}
            selectedLanguage={currentLanguage}
            onChangeLanguage={changeLanguage}
            isAuthenticated={isAuthenticated}
            tenantLogoUrl={logo}
          />
        </div>
      )}

      <div
        className={twMerge(
          clsx(
            'fixed left-[-300px] top-0 z-[1000] block h-screen w-[300px] transition-[left] duration-300 ease-in-out md:hidden',
            {
              'left-0': isDrawerMenuOpen
            }
          )
        )}
      >
        {isDrawerMenuContentVisible && (
          <SidebarNavigation
            navigationItems={isAuthenticated ? navigationItems : isSignedOutItems}
            manageAccountItems={isAuthenticated ? manageAccountItems : []}
            onClickItem={onClickItem}
            activePath={history.location.pathname}
            languages={languages}
            isMobile={true}
            onClickClose={toggleDrawerMenu}
            selectedLanguage={currentLanguage}
            onChangeLanguage={changeLanguage}
            isAuthenticated={isAuthenticated}
            tenantLogoUrl={logo}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { route } = routeSelector(state);
  const locale = localeSelector(state);
  const { options, lang } = languageSelector(state);
  const { isManualSwitch } = appSelector(state);
  const { tenantSettings } = tenantSettingsSelector(state);
  const { appMenuHidden, fullName } = appMenuSelector(state);
  const { billsCount, incomingPaymentsCount } = sidebarNavigationSelector(state);
  const isDrawerMenuOpen = getIsDrawerMenuOpen(state);

  return {
    locale,
    isManualSwitch,
    languages: options,
    currentLanguage: lang,
    route,
    tenantSettings,
    appMenuHidden,
    isDrawerMenuOpen,
    fullName,
    billsCount,
    incomingPaymentsCount
  };
};

const connector = connect(mapStateToProps, {
  getLocale: LocaleCreators.getLocale as GetLocale,
  triggerModal: triggerModal,
  signOut: AccountCreators.signOut,
  deleteUser: AccountCreators.deleteUser,
  toggleDrawerMenu
});

type NavigationContainerPropsFromRedux = ConnectedProps<typeof connector>;

export const SidebarNavigationContainer = connector(Navigation);
