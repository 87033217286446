import { SVGProps } from 'react';

export const UserIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#clip0_516_18238)">
        <path
          d="M8.99986 9C11.3076 9 13.1784 7.12919 13.1784 4.82142C13.1784 2.51366 11.3076 0.642853 8.99986 0.642853C6.6921 0.642853 4.82129 2.51366 4.82129 4.82142C4.82129 7.12919 6.6921 9 8.99986 9Z"
          stroke="#1C213C"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
        <path
          d="M16.9456 17.3571C16.4059 15.674 15.3455 14.2057 13.9175 13.164C12.4895 12.1223 10.7675 11.5609 8.99991 11.5609C7.23231 11.5609 5.51036 12.1223 4.08233 13.164C2.65431 14.2057 1.59397 15.674 1.0542 17.3571H16.9456Z"
          stroke="#1C213C"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
      </g>
    </svg>
  );
};
