import { SVGProps } from 'react';

export const CreditCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="money-atm-card-1--credit-pay-payment-debit-card-finance-plastic-money">
        <g id="Group">
          <path
            id="Vector"
            d="M17.3571 11.5V4.17854C17.3571 3.46846 16.7815 2.89282 16.0714 2.89282H1.92854C1.21846 2.89282 0.642822 3.46846 0.642822 4.17854V13.8214C0.642822 14.5315 1.21846 15.1071 1.92854 15.1071H10.5"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_2"
            d="M0.642822 7.39282H17.3571"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <path
            id="Vector_3"
            d="M12.2144 11.8928H14.1429"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <g id="Group 33">
            <path
              id="Vector_4"
              d="M13 15.2244H17"
              stroke="#1C213C"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="path"
            />
            <path
              id="Vector_5"
              d="M15.8569 14.0815L16.9998 15.2244L15.8569 16.3673"
              stroke="#1C213C"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
