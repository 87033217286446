import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getTransferringAccounts } from 'selectors/accountsSelector';
import { RootState } from 'store/store';
import { Types } from 'actions/mandates';

import { calculateTotalLengthOfArrays } from 'utils/array';
import { calculateProgressPercentage } from 'utils/progress';

import { getCloseAccountStatus } from './closeAccountSelector';
import { getDirectDebits } from './directDebitsSelectors';
import { incomingPaymentsProgress } from './incomingPaymentsSelectors';
import { getTransferBalanceResponseData } from './transferBalanceSelector';

export const billsProgress = (state: RootState) => {
  const {
    toAddAddress = [],
    toAdvice = [],
    toSign = [],
    transferred = [],
    ignored = []
  } = getDirectDebits(state);

  const doneDirectDebits = calculateTotalLengthOfArrays(transferred, ignored);
  const totalDirectDebits = calculateTotalLengthOfArrays(
    toAddAddress,
    toAdvice,
    toSign,
    transferred,
    ignored
  );

  const data = get(state.api, [Types.GET_STANDING_ORDERS, 'data'], {});
  const { created = [], toTransfer = [], transferred: transferredStandingOrders = [] } = data;

  const doneStandingOrders = transferredStandingOrders.length;
  const totalStandingOrders = created.length + toTransfer.length + transferredStandingOrders.length;

  const progress = calculateProgressPercentage({
    divisor: doneDirectDebits + doneStandingOrders,
    divider: totalDirectDebits + totalStandingOrders,
    decimals: 0
  });

  return {
    done: doneDirectDebits + doneStandingOrders,
    total: totalDirectDebits + totalStandingOrders,
    progress,
    isComplete: progress === 100
  };
};

export const overviewSelector = createSelector(
  [
    getTransferBalanceResponseData,
    billsProgress,
    incomingPaymentsProgress,
    getCloseAccountStatus,
    getTransferringAccounts
  ],
  (balance, billsProgress, incomingPaymentsProgress, isAccountClosed, transferringAccounts) => {
    return {
      balance,
      billsProgress,
      isAccountClosed,
      incomingPaymentsProgress,
      transferringAccounts
    };
  }
);
