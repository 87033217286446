import get from 'lodash/get';
import has from 'lodash/has';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { BankLoginFormData, RegistrationStatus, Types as AccountTypes } from 'actions/account';
import { Types as BankTypes } from 'actions/banks';
import { Bank } from 'models/Bank';
import { RootState } from 'store/store';

import { authStatus } from './appSelectors';

const getRegShieldLocation: Selector<RootState, string> = ({ api }) => {
  const location = get(api, [AccountTypes.START_REG_SHIELD_REGISTRATION, 'data', 'location']);

  return location ? `${location}&ask_ui=true` : location;
};

const getIsFetchingRegShieldLocation: Selector<RootState, boolean> = ({ api }) =>
  get(api, [AccountTypes.START_REG_SHIELD_REGISTRATION, 'isFetching'], false);

export const getBankSelectionList: Selector<RootState, Bank[]> = ({ api }) =>
  get(api, [BankTypes.GET_BANK_SELECTIONS, 'data'], []);

export const getIsFetchingBankSelectionList: Selector<RootState, boolean> = ({ api }) =>
  get(api, [BankTypes.GET_BANK_SELECTIONS, 'isFetching'], false);

const getBankLoginFormData: Selector<RootState, BankLoginFormData> = ({ api }) =>
  get(api, [AccountTypes.OLD_BANK_LOGIN_FORM, 'data'], {});

const getIsFetchingBankLoginFormData: Selector<RootState, boolean> = ({ api }) =>
  get(api, [AccountTypes.OLD_BANK_LOGIN_FORM, 'isFetching'], false);

const getRegistrationStatus: Selector<RootState, RegistrationStatus> = ({ api }) =>
  get(api, [AccountTypes.OLD_BANK_LOGIN_MFA_STATUS, 'data'], {});

export const getIsRegShieldRegistrationInProgress: Selector<RootState, boolean> = ({ api }) =>
  has(api, AccountTypes.COMPLETE_REG_SHIELD_REGISTRATION);

const getIsLoginToOldBankInProgress: Selector<RootState, boolean> = ({ app }) => app.showLoader;

export const oldBankSelector = createSelector(
  [
    getBankSelectionList,
    getIsFetchingBankSelectionList,
    getBankLoginFormData,
    getIsFetchingBankLoginFormData,
    authStatus,
    getRegShieldLocation,
    getIsFetchingRegShieldLocation,
    getRegistrationStatus,
    getIsRegShieldRegistrationInProgress,
    getIsLoginToOldBankInProgress
  ],
  (
    bankSelectionList,
    isFetchingBankSelectionList,
    bankLoginFormData,
    isFetchingBankLoginFormData,
    isAuthenticated,
    regShieldLocation,
    isFetchingRegShieldLocation,
    registrationStatus,
    isRegShieldRegistrationInProgress,
    isLoginToOldBankInProgress
  ) => {
    return {
      bankSelectionList,
      isFetchingBankSelectionList,
      bankLoginFormData,
      isFetchingBankLoginFormData,
      isAuthenticated,
      regShieldLocation,
      isFetchingRegShieldLocation,
      registrationStatus,
      isRegShieldRegistrationInProgress,
      isLoginToOldBankInProgress
    };
  }
);
