import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { Types as AccountTypes } from 'actions/account';
import { Types } from 'actions/mandates';

import { calculateTotalLengthOfArrays } from 'utils/array';
import { removeAdditionalFields } from 'utils/mandate';
import { calculateProgressPercentage } from 'utils/progress';

const getAnalyzedDaysCount = ({ api }) => {
  const { daysAnalyzedCount } = get(api, [AccountTypes.GET_PERSONAL_DETAILS, 'data'], {});

  return {
    daysAnalyzedCount: daysAnalyzedCount || 0
  };
};

export const getIncomingPayments = ({ api }) => {
  const incomingPayments = get(api, [Types.GET_INCOMING_PAYMENTS, 'data'], {});

  return removeAdditionalFields(incomingPayments, ['birth_date']);
};

export const incomingPaymentsProgress = state => {
  const {
    creditOneTime = [],
    creditMultiple = [],
    transferred = [],
    ignored = []
  } = getIncomingPayments(state);

  const done = calculateTotalLengthOfArrays(transferred, ignored);
  const total = calculateTotalLengthOfArrays(creditOneTime, creditMultiple, transferred, ignored);
  const progress = calculateProgressPercentage({ divisor: done, divider: total, decimals: 0 });

  return {
    done,
    total,
    progress,
    isComplete: progress === 100
  };
};

export const incomingPaymentsMandates = state => {
  const {
    creditOneTime = [],
    creditMultiple = [],
    transferred = [],
    ignored = []
  } = getIncomingPayments(state);

  const toSign = [...creditOneTime, ...creditMultiple].filter(mandate => !mandate.switchedByAdvice);
  const toAdvice = [...creditOneTime, ...creditMultiple].filter(
    mandate => mandate.switchedByAdvice
  );

  return {
    active: {
      toSign,
      toAdvice
    },
    done: {
      transferred,
      ignored
    }
  };
};

export const incomingPaymentsSelector = createSelector(
  [incomingPaymentsMandates, getAnalyzedDaysCount],
  (mandates, analyzedDaysCount) => {
    return {
      mandates,
      analyzedDaysCount
    };
  }
);
