import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { FC, SVGProps, JSX } from 'react';
import { Route } from 'slices/routes';

export interface SidebarNavigationItemProps {
  title: string;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  dataId: string;
  routeTo: Route['path'];
  activePath: Route['path'];
  onClick: (path: Route['path']) => void;
  count?: number;
  htmlTag?: keyof JSX.IntrinsicElements;
}

export const SidebarNavigationItem = ({
  title,
  IconComponent,
  dataId,
  routeTo,
  activePath,
  onClick,
  count,
  htmlTag,
  ...rest
}: SidebarNavigationItemProps) => {
  const HtmlElement = htmlTag ?? 'li';
  const isItemActive = routeTo === activePath;

  return (
    <HtmlElement
      data-testid={dataId}
      data-active={isItemActive}
      className={twMerge(
        clsx(
          'group flex cursor-pointer rounded-lg text-general-80 transition-all',
          'duration-150 hover:bg-primary-hover-light dark:text-white dark:hover:bg-white-hover-grey',
          {
            'bg-primary-100 text-white dark:bg-secondary-40 dark:text-general-100 hover:bg-primary-100 dark:hover:bg-secondary-40':
              isItemActive
          }
        )
      )}
      {...rest}
    >
      <button
        data-testid={`${dataId}-button`}
        onClick={() => onClick(routeTo)}
        className="flex w-full flex-wrap items-center justify-between px-4 py-2.5 text-base"
        type="button"
      >
        <div
          className={twMerge(
            clsx('flex basis-10/12 items-center gap-x-2', {
              'basis-full': !count
            })
          )}
        >
          <IconComponent
            data-testid={`${dataId}-icon`}
            className={twMerge(
              clsx('dark:[&_.path]:stroke-white', {
                '[&_.path]:stroke-white dark:[&_.path]:stroke-general-100': isItemActive
              })
            )}
          />
          <span className="flex basis-11/12 text-left" data-testid={`${dataId}-title`}>
            {title}
          </span>
        </div>
        {Boolean(count) && (
          <div
            data-testid={`${dataId}-count`}
            className={twMerge(
              clsx('min-w-8 rounded-xl bg-secondary-40 p-1 text-sm font-bold', 'text-general-100', {
                'group-hover:bg-secondary-80 dark:group-hover:bg-secondary-10': !isItemActive,
                'dark:bg-secondary-100': isItemActive
              })
            )}
          >
            {count}
          </div>
        )}
      </button>
    </HtmlElement>
  );
};
