import { SVGProps } from 'react';

export const CatalanFlagIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" {...props}>
      <g id="00 General">
        <path id="Vector" d="M0 0.000488281H24V17.9996H0V0.000488281Z" fill="#FCDD09" />
        <g id="Vector_2">
          <path
            d="M0 3.00049H23.9999ZM23.9999 7.0003H0ZM0 11.0001H23.9999ZM23.9999 14.9999H0Z"
            fill="black"
          />
          <path
            d="M0 3.00049H23.9999M23.9999 7.0003H0M0 11.0001H23.9999M23.9999 14.9999H0"
            stroke="#DA121A"
            strokeWidth="1.88562"
          />
        </g>
      </g>
    </svg>
  );
};
