import { SVGProps } from 'react';

export const SignOutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g
        id="interface-logout--arrow-exit-frame-leave-logout-rectangle-right"
        clipPath="url(#clip0_1966_3619)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M13.5 5.7855L16.0714 5.7855C16.4124 5.7855 16.7394 5.92096 16.9805 6.16208C17.2216 6.4032 17.3571 6.73023 17.3571 7.07122L17.3571 16.0712C17.3571 16.4122 17.2216 16.7392 16.9805 16.9804C16.7394 17.2215 16.4124 17.3569 16.0714 17.3569L1.92854 17.3569C1.58754 17.3569 1.26052 17.2215 1.0194 16.9804C0.778281 16.7392 0.642822 16.4122 0.642822 16.0712L0.642822 7.07122C0.642822 6.73023 0.778281 6.4032 1.0194 6.16208C1.26052 5.92096 1.58754 5.78551 1.92854 5.78551L4.49996 5.78551"
            stroke="#1C213C"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="path"
          />
          <g id="Group 33">
            <path
              id="Vector_2"
              d="M9 9.64258L9 0.642578"
              stroke="#1C213C"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="path"
            />
            <path
              id="Vector_3"
              d="M6.42847 3.21387L8.9999 0.642439L11.5713 3.21387"
              stroke="#1C213C"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="path"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1966_3619">
          <rect width="18" height="18" fill="white" transform="translate(0 18) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};
