import get from 'lodash/get';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { Types as AccountTypes } from 'actions/account';
import { RootState } from 'store/store';

import { userInitialState } from 'enums/signup';

import { personalDetailsResponseData } from './personalDetailsSelector';

const isFetchingStatus: Selector<RootState, boolean> = ({ app }) => app.isFetching;

export const authStatus: Selector<RootState, boolean> = ({ app }) => app.isAuthenticated;

const getMenuDisplayStatus: Selector<RootState, boolean> = ({ app }) => app.appMenuHidden;

const getIsRegisteredFromLink: Selector<RootState, boolean> = ({ api }) => {
  const { initialState } = get(api, [AccountTypes.GET_PERSONAL_DETAILS, 'data'], {});
  return initialState === userInitialState.registeredFromLink;
};

export const getIsManualSwitch: Selector<RootState, boolean> = ({ api }) => {
  const { isManualSwitch } = get(api, [AccountTypes.GET_PERSONAL_DETAILS, 'data'], {});
  return isManualSwitch;
};

export const appSelector = createSelector(
  [authStatus, isFetchingStatus, getIsRegisteredFromLink, getIsManualSwitch],
  (isAuthenticated, isFetching, isRegisteredFromLink, isManualSwitch) => {
    return {
      isManualSwitch,
      isAuthenticated,
      isFetching,
      isRegisteredFromLink
    };
  }
);

export const appMenuSelector = createSelector(
  [personalDetailsResponseData, getMenuDisplayStatus],
  (account, appMenuHidden) => {
    const { firstName, lastName } = account;
    const fullName = `${firstName} ${lastName}`;

    return {
      fullName,
      appMenuHidden
    };
  }
);
