import { FC, HTMLAttributes, ReactNode, SVGProps } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { t } from 'locale';
import { noop } from 'lodash';

import { HamburgerMenuIcon } from 'components/Icons/V2/HamburgerMenuIcon';
import { UserIcon } from 'components/Icons/V2/UserIcon';
import { Language } from 'locale/reducer';
import { CloseIcon } from 'components/Icons/V2/CloseIcon';

import { localeKeys } from 'enums/localeKeys';

import { HeaderLanguageSelection } from './HeaderLanguageSelection';
import { HeaderProfileMenu } from './HeaderProfileMenu';

import { DropdownSelectorItem } from '../DropdownSelector/DropdownSelector';

type ProfileMenuItemOnClickItem = (item: ProfileMenuItem) => void;

export interface ProfileMenuItem extends DropdownSelectorItem {
  Icon: FC<SVGProps<SVGSVGElement>>;
  onClick: ProfileMenuItemOnClickItem;
}

export type RenderLogoItem = ({ onClickLogo }: { onClickLogo: () => void }) => ReactNode;

export interface LanguageOption {
  label: string;
  value: Language;
}

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
  name: string;
  isLoggedIn: boolean;
  onClickLogin: () => void;
  onClickLogo?: () => void;
  userName: string;
  profileMenuItems: ProfileMenuItem[];
  languages: LanguageOption[];
  selectedLanguage: LanguageOption;
  onChangeLanguage?: (language: LanguageOption) => void;
  renderLogo?: RenderLogoItem;
  isCollapsed: boolean;
  onChangeCollapse?: () => void;
}

export const Header = ({
  isLoggedIn,
  onClickLogin = noop,
  onClickLogo = noop,
  userName,
  profileMenuItems,
  renderLogo,
  languages,
  selectedLanguage,
  onChangeLanguage = noop,
  isCollapsed,
  onChangeCollapse = noop,
  ...props
}: HeaderProps) => {
  return (
    <header
      className="sticky top-0 z-[100] flex h-[72px] w-full flex-wrap bg-white px-6 py-4 text-sm shadow-header dark:bg-general-90 md:flex-nowrap md:justify-start"
      {...props}
    >
      <nav className="size-full md:flex md:items-center md:justify-between">
        <div className="flex items-center justify-between">
          {renderLogo ? (
            renderLogo({ onClickLogo })
          ) : (
            <button
              type="button"
              className="focus:opacity-80 focus:outline-none dark:text-white"
              aria-label="Logo"
              onClick={() => onClickLogo()}
            >
              <img
                src="/company_logo.png"
                alt="logo"
                className="h-5 w-[71px]"
                data-testid="header-company-logo"
              />
            </button>
          )}
          <div className="md:hidden">
            <button
              type="button"
              className="relative flex size-7 items-center justify-center gap-x-2 bg-white text-gray-800 dark:bg-transparent dark:text-white"
              aria-expanded={!isCollapsed}
              aria-label={t(localeKeys.navigationToggle)}
              data-testid="header-toggle-navigation"
              onClick={() => onChangeCollapse()}
            >
              <HamburgerMenuIcon
                className={twMerge(
                  clsx(
                    'absolute opacity-0 transition-all duration-300 dark:[&_.path]:stroke-white',
                    {
                      [`opacity-100`]: isCollapsed
                    }
                  )
                )}
              />

              <CloseIcon
                className={twMerge(
                  clsx(
                    'absolute opacity-100 transition-all duration-300 dark:[&_.path]:stroke-white',
                    {
                      [`opacity-0`]: isCollapsed
                    }
                  )
                )}
              />

              <span className="sr-only">{t(localeKeys.navigationToggle)}</span>
            </button>
          </div>
        </div>
        <div
          id="navbar-items"
          className="hidden grow basis-full transition-all duration-300 md:block"
        >
          <div className="flex flex-row items-center justify-end gap-2 ps-5">
            {isLoggedIn ? (
              <HeaderProfileMenu userName={userName} profileMenuItems={profileMenuItems} />
            ) : (
              <button
                type="button"
                className="text-body-standard-regular flex items-center gap-2 text-gray-800 dark:text-white"
                data-testid="header-login-button"
                onClick={onClickLogin}
              >
                <UserIcon className="[&_.path]:stroke-white dark:[&_.path]:stroke-white" />
                {t(localeKeys.loginButtonText)}
              </button>
            )}

            <HeaderLanguageSelection
              languages={languages}
              selectedLanguage={selectedLanguage}
              onChangeLanguage={onChangeLanguage}
            ></HeaderLanguageSelection>
          </div>
        </div>
      </nav>
    </header>
  );
};
