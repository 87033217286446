import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

const initialState = {
  message: '',
  reload: false,
  redirected: false,
  networkUnavailable: false,
  uuid: ''
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    triggerError: (
      state,
      action: PayloadAction<{
        message: string;
        options?: {
          redirected?: boolean;
          reload?: boolean;
          networkUnavailable?: boolean;
        };
      }>
    ) => {
      const { message, options } = action.payload;
      state.message = message;
      state.reload = options?.reload ?? initialState.reload;
      state.redirected = options?.redirected ?? initialState.redirected;
      state.networkUnavailable = options?.networkUnavailable ?? initialState.networkUnavailable;
      state.uuid = nanoid();
    },
    clearError: () => {
      return initialState;
    }
  },
  selectors: {
    getAppError: state => {
      const { message, reload, redirected, networkUnavailable, uuid } = state;

      if (message) {
        return {
          reload,
          redirected,
          networkUnavailable,
          uuid,
          content: [
            {
              message
            }
          ]
        };
      }
    }
  }
});

export const { triggerError, clearError } = errorSlice.actions;
export const { getAppError } = errorSlice.selectors;
