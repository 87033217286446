import React from 'react';

import { ProgressBar } from 'components/_V2/ProgressBar/ProgressBar';

import { calculateProgressPercentage } from 'utils/progress';

export interface OnboardingProgressBarProps {
  currentStep: number;
  totalSteps: number;
  isVisible: boolean;
}

export const OnboardingProgressBar = ({
  currentStep,
  isVisible,
  totalSteps
}: OnboardingProgressBarProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <ProgressBar
      name="onboarding-progres-bar"
      value={calculateProgressPercentage({ divisor: currentStep, divider: totalSteps })}
    />
  );
};
