import chroma from 'chroma-js';

export function generateColorPalette(
  paletteName = 'primary',
  baseColor: string
): Record<string, number[]> {
  const palette: Record<string, number[]> = {};
  const steps: Record<string, number> = {
    '5': 0.05,
    '10': 0.1,
    '20': 0.2,
    '30': 0.3,
    '40': 0.4,
    '50': 0.5,
    '60': 0.6,
    '70': 0.7,
    '80': 0.8,
    '90': 0.9,
    '100': 1
  };

  Object.entries(steps).forEach(([key, value]) => {
    const color = chroma.mix('white', baseColor, value, 'lab');
    palette[`${paletteName}-${key}`] = color.rgb();
  });

  const baseColorHsl = chroma(baseColor).hsl();
  palette[`${paletteName}-hover`] = chroma
    .hsl(baseColorHsl[0], baseColorHsl[1], baseColorHsl[2] * 0.5)
    .rgb();
  palette[`${paletteName}-hover-light`] = palette[`${paletteName}-10`];

  return palette;
}

export function generateStyles(palettes: Record<string, number[]>): string {
  return Object.entries(palettes).reduce((acc, [key, value]) => {
    return `${acc}
      --color-${key}: ${value};
    `;
  }, '');
}
